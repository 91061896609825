import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./style.scss";
class AlertDialog extends React.Component {
  state = {
    open: this.props.isOpen,
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="file-process-container">
            <div className="custom-spinner">
              {" "}
              <CircularProgress color="secondary" />
            </div>

            <div className="file-process-message">
              <h4>Processing your Request...</h4>
              <h6 style={{ textTransform: "none" }}>
                Please wait and do not refresh your page while we process your
                request.
              </h6>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
