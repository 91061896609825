/**
 * Menu List Item
 */
import React, { Fragment, Component } from "react";
import { List, ListItem, Collapse } from "@material-ui/core";
import { NavLink } from "react-router-dom";
// intl messages
import JobsSvgComponent from "../../../assets/svgcomponents/JobsSvgComponent";
import AdvertisingSvgComponent from "../../../assets/svgcomponents/AdvertisingSvgComponent";
import AnnouncementsSvgComponent from "../../../assets/svgcomponents/AnnouncementsSvgComponent";
import CategoryMappingSvgComponent from "../../../assets/svgcomponents/CategoryMappingSvgComponent";
import CustomersSvgComponent from "../../../assets/svgcomponents/CustomersSvgComponent";
import FAQSvgComponent from "../../../assets/svgcomponents/FAQSvgComponent";
import GroupsSvgComponent from "../../../assets/svgcomponents/GroupsSvgComponent";
import ManufacturerMappingSvgComponent from "../../../assets/svgcomponents/ManufacturerMappingSvgComponent";
import NotificationsSvgComponent from "../../../assets/svgcomponents/NotificationsSvgComponent";
import OrdersSvgComponent from "../../../assets/svgcomponents/OrdersSvgComponent";
import PracticesSvgComponent from "../../../assets/svgcomponents/PracticesSvgComponent";
import ProductMappingSvgComponent from "../../../assets/svgcomponents/ProductMappingSvgComponent";
import ProductsSvgComponent from "../../../assets/svgcomponents/ProductsSvgComponent";
import ProgramsSvgComponent from "../../../assets/svgcomponents/ProgramsSvgComponent";
import SystemConfigurationSvgComponent from "../../../assets/svgcomponents/SystemConfigurationSvgComponent";
import VendorsSvgComponent from "../../../assets/svgcomponents/VendorsSvgComponent";
import WhiteLabelsSvgComponent from "../../../assets/svgcomponents/WhiteLabelsSvgComponent";
import ReleaseNotesSvgComponent from "assets/svgcomponents/ReleaseNotesSvgComponent";

class NavMenuItem extends Component {
  /**
   * On Toggle  Menu
   */
  onToggleMenu() {
    this.props.toggleMenu();
  }

  render() {
    const { menu, toggleMenu, statusAvailable } = this.props;
    const menuSvgs = [
      {
        icon: (
          <JobsSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Jobs",
      },
      {
        icon: (
          <AdvertisingSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Advertisements",
      },
      {
        icon: (
          <AnnouncementsSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Announcements",
      },
      {
        icon: (
          <CategoryMappingSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Category Mapping",
      },
      {
        icon: (
          <CustomersSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Customers",
      },
      {
        icon: (
          <FAQSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "FAQ",
      },
      {
        icon: (
          <GroupsSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Groups",
      },
      {
        icon: (
          <ManufacturerMappingSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Manufacturer Mapping",
      },
      {
        icon: (
          <NotificationsSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Notifications",
      },
      {
        icon: (
          <OrdersSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Orders",
      },
      {
        icon: (
          <PracticesSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Practices",
      },
      {
        icon: (
          <ProductMappingSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Product Mapping",
      },
      {
        icon: (
          <ProductsSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Products",
      },
      {
        icon: (
          <ProgramsSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Programs",
      },
      {
        icon: (
          <SystemConfigurationSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "System Configuration",
      },
      {
        icon: (
          <VendorsSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Vendors",
      },
      {
        icon: (
          <WhiteLabelsSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "White Labels",
      },
      {
        icon: (
          <ReleaseNotesSvgComponent
            color={
              menu.isMenuOpen === true ? "#00aaff" : "rgba(245,246,250,0.7)"
            }
          />
        ),
        menuKey: "Release Notes",
      },
    ];
    const menuIcon = menuSvgs.find((item) => item.menuKey === menu.menu_title);
    if (menu.child_routes != null) {
      return (
        <Fragment>
          <ListItem
            disableRipple
            button
            component="li"
            onClick={() => toggleMenu()}
            className={menu.isMenuOpen === true ? "item-active" : ""}
          >
            <span
              className="d-flex justify-content-start align-items-center"
              style={{
                color:
                  menu.isMenuOpen === true
                    ? "#00aaff"
                    : "rgba(245,246,250,0.7)",
              }}
            >
              {menuIcon?.icon}
              {menu.menu_title}
            </span>
          </ListItem>
          <Collapse in={menu.isMenuOpen} timeout="auto" unmountOnExit>
            <List component="ul" className="sub-menu">
              {menu.child_routes.map((subMenu, index) => {
                return (
                  <ListItem key={index} button component="li">
                    <NavLink activeClassName="link-active" to={subMenu.path}>
                      {subMenu.menu_title}
                      {subMenu.status_check && statusAvailable && (
                        <span
                          class="material-icons"
                          style={{ color: "red", verticalAlign: "-7px" }}
                        >
                          priority_high
                        </span>
                      )}
                    </NavLink>
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </Fragment>
      );
    }
    return (
      <ListItem
        disableRipple
        button
        component="li"
        onClick={() => toggleMenu()}
      >
        <NavLink
          activeClassName="link-active"
          to={menu.path}
          className="d-flex justify-content-start align-items-center"
        >
          {menuIcon.icon}
          {menu.menu_title}
        </NavLink>
      </ListItem>
    );
  }
}

export default NavMenuItem;
