import axios from 'axios'
import { getAccessToken, getAdditionalHeaders } from '../Authorization';
import { local } from '../constant';
const noAuthInstance = axios.create();

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
}

const request = (params, baseUrl = local.baseUrl) => {
  headers['Authorization'] = "bearer " + getAccessToken()

  return axios({
    headers: Object.assign(
      {},
      {
        ...headers,
        ...getAdditionalHeaders()
      },
    ),
    method: params.method,
    params: params.queryParams,
    url: baseUrl + params.url,
    data: params.data
  })
}

const requestNoAuth = (params, baseUrl = local.baseUrl) => {
  return noAuthInstance({
    headers: Object.assign(
      {},
      headers,
    ),
    method: params.method,
    params: params.queryParams,
    url: baseUrl + params.url,
    data: params.data
  })
}

delete noAuthInstance.defaults.headers.common['Authorization'];
export const getNoAuth = (params) => requestNoAuth(Object.assign({ method: 'GET', ...params }))
export const postNoAuth = (params) => requestNoAuth(Object.assign({ method: 'POST', ...params }))

export const get = (params) => request(Object.assign({ method: 'GET', ...params }))
export const post = (params) => request(Object.assign({ method: 'POST', ...params }))
export const put = (params) => request(Object.assign({ method: 'PUT', ...params }))
export const del = (params) => request(Object.assign({ method: 'DELETE', ...params }))
export const patch = (params) => request(Object.assign({ method: 'PATCH', ...params }))
