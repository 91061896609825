import * as React from "react";

function ProductMappingSvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ marginRight: "10px" }}
    >
      <path
        d="M16.6312 4.92188L16.6406 4.91719H16.6453L12 2.25L7.35703 4.91719H7.35234L7.36875 4.92422L3.55078 7.125V16.875L12 21.75L20.4492 16.875V7.125L16.6312 4.92188ZM11.3133 19.7719L7.95938 17.8359V14.5641L4.92188 12.8391V8.73984L11.3133 12.4266V19.7719ZM5.58281 7.53516L8.73984 5.71172L11.9953 7.57969L15.2555 5.70703L18.4242 7.53516L12.0047 11.2383L5.58281 7.53516ZM19.0781 12.8508L16.0594 14.5641V17.8242L12.6867 19.7695V12.4313L19.0781 8.74453V12.8508Z"
        fill={props.color}
        fill-opacity="0.7"
      />
    </svg>
  );
}

export default ProductMappingSvgComponent;
