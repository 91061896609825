export const GET_CUSTOMER = () => {
    return `api/v3/customers`
}

export const CUSTOMER_LOGIN = () => {
    return 'api/v2/customer/login'
}

export const CUSTOMER_UNLOCK = () => {
    return `api/v3/customer/unlock-account`
}
export const GET_SUPPLIER_URL = (supplierId) => {
    return `api/v4/store/login-type/${supplierId}`
}

export const CUSTOMER_FORGOT_PASSWORD = (email) => {
    return `api/v1/customer/forgot-password?email=${email}`
}
export const THIRD_PARTY_LOGIN = (accessToken) => {
    return `api/v1/auth/redirect?${accessToken}`
}