/**
 * Reactify Sidebar
 */
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import $ from "jquery";
import { List } from "@material-ui/core";
import { local } from "../../../constant";
// redux actions
import { collapsedSidebarAction } from "../../../actions/action";

// components
import NavMenuItem from "./NavMenuItem";
import Navlinks from "./Navlinks";
import AppConfig from "../../../constants/AppConfig";
import { getAdditionalHeaders } from "../../../Authorization";

class AdminSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navLinks: Navlinks,
      statusAvailable: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    var sessionData = JSON.parse(sessionStorage.getItem("token"));
    if (sessionData) this.vendorList();
  }

  vendorList() {
    var sessionData = JSON.parse(sessionStorage.getItem("token"));
    fetch(local.baseUrl + `api/v1/admin/pending-unknown-status-available`, {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          this.setState({ loading: false });
          return;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data && data.available) {
          this.setState({ statusAvailable: data.available });
        }
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentWillReceiveProps(nextProps) {
    const { windowWidth } = this.state;
    if (nextProps.location !== this.props.location) {
      if (windowWidth <= 1199) {
        this.props.collapsedSidebarAction(false);
      }
    }
  }

  updateDimensions = () => {
    this.setState({
      windowWidth: $(window).width(),
      windowHeight: $(window).height(),
    });
  };

  toggleMenu(index) {
    let navlinksArray = this.state.navLinks;
    for (let i = 0; i < navlinksArray.length; i++) {
      if (i === index) {
        if (navlinksArray[index].isMenuOpen) {
          navlinksArray[index].isMenuOpen = false;
        } else {
          navlinksArray[index].isMenuOpen = true;
        }
      } else {
        navlinksArray[i].isMenuOpen = false;
      }
    }
    this.setState({ navLinks: navlinksArray });
  }

  render() {
    const { navLinks } = this.state;
    return (
      <Fragment>
        <div className="admin-sidebar-wrap">
          <div className="sidebar-content">
            <div
              className="site-logo-wrap text-center"
              style={{ display: "flex", alignItems: "center",paddingTop:"30px" }}
            >
              <Link to="#" className="py-10 px-10 d-inline-block">
                <img
                  src={
                    this.props.appAttributes &&
                    this.props.appAttributes.LOGO_URL
                      ? this.props.appAttributes.LOGO_URL
                      : AppConfig.AppLogo
                  }
                  alt="header-logo"
                  style={{ height: "54px", width: "54px" }}
                />
              </Link>
              <h4
                className="m-0"
                style={{ color: "white", fontSize: "24px", fontWeight: "400" }}
              >
                VetScout
              </h4>
            </div>
            <div className="rct-sidebar-wrap">
              {/* <Scrollbars
                className="rct-scroll"
                autoHide
                autoHideDuration={100}
                style={{ height: "calc(100vh - 140px)" }}
              > */}
              <div className="admin-menu pt-10">
                <List component="nav" className="iron-sidebar-menu">
                  {navLinks &&
                    navLinks.map((NavLink, index) => (
                      <NavMenuItem
                        statusAvailable
                        menu={NavLink}
                        key={index}
                        toggleMenu={() => this.toggleMenu(index)}
                      />
                    ))}
                </List>
              </div>
              {/* </Scrollbars> */}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// map state to props
const mapStateToProps = ({ appSettings }) => {
  const { collapsedSidebar, appAttributes } = appSettings;
  return { collapsedSidebar, appAttributes };
};

export default withRouter(
  connect(mapStateToProps, {
    collapsedSidebarAction,
  })(AdminSidebar)
);
