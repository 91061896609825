import React from "react";

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const withStylesProps = styles =>
   Component =>
      props => {
         const TooltipComp = withStyles(theme=> styles(theme, props))(Component);
         return <TooltipComp {...props}>{props.children}</TooltipComp>
      }
    
const styles = (theme, props) => ({
   tooltip: {
      backgroundColor: '#fff',
      color: '#000',
      maxWidth: props.maxWidth ?? 250,
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #dadde9',
      textAlign: 'center'
   },
})

export const HtmlTooltip = withStylesProps(styles)(Tooltip);