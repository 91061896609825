/**
 * Ecommerce Reducer
 */

//action types

import {
  ADD_TO_CART,
  ADD_TO_WISHLIST,
  REMOVE_FROM_CART,
  UPDATE_CART,
  UPDATE_PRODUCT_QUANTITY,
  FINAL_PAYMENT,
  MOVE_WISHLISTITEM_TO_CART,
  REMOVE_FROM_WISHLIST,
  DELETE_USER,
  ADD_NEW_USER,
  DISPLAY_CART_ITEMS,
  DISPLAY_WISHLIST_ITEMS,
  SHOW_VENDOR_LIST,
  GET_MYCOVETRUS_DATA,
  DEFAULT_MERCHANT_DETAILS,
  SAVE_MERCHANT_DETAILS,
  UPDATE_MERCHANT_DETAILS,
  ORDER_HISTORY,
  PRODUCT_ORDER_HISTORY,
  MANUFACURE_ORDER_HISTORY,
  PROFILE,
  LOGOUT,
  COMPANIES,
  ACTIVE_COMPANY,
  COMPANY_UPDATE_FLAG,
  NOTIFICATIONS,
  POPUP_NOTIFICATION,
  NOTIFICATION_COUNT,
  DISPLAY_MY_PRODUCTS_ITEMS,
  DISPLAY_MERCHANT_CART_ITEMS,
  MANUFACTURER_PURCHASE_SUMMARY_REPORT,
  MERCHANT_PURCHASE_SUMMARY_REPORT,
  PRODUCT_PURCHASE_SUMMARY_REPORT,
  CATEGORY_PURCHASE_SUMMARY_REPORT,
  MANUFACTURER_FROM_REBATE,
  SHOW_GROUP_LIST,
  GROUP_AREA_MANAGERS,
  CARTITEMS,
  SHOW_ADD_TO_CART_POPUP,
  SET_ROLE,
  BUCKS,
  SHOW_VENDOR_CONNECT_POPUP,
  HIDE_VENDOR_CONNECT_POPUP,
  SET_INVALID_CREDENTIALS,
  SET_EXPIRED_TOKEN,
  BUDGETS,
  SHOW_SAVINGS_POPUP,
  HIDE_SAVINGS_POPUP,
  COMPANY_WISH_LIST,
  ADD_FAVORITE_ITEM,
  DELETE_FAVORITE_ITEM,
  UPDATE_FAVORITE_ITEM,
} from "../actions/types";

const INITIAL_STATE = {
  cart: [],
  merchantCart: [],
  wishlist: [],
  receiptProducts: [],
  totalPrice: 0,
  total: 0,
  vendorList: [],
  role: "",
  manufacturerPurchaseSummary: {},
  merchantPurchaseSummary: {},
  productPurchaseSummary: {},
  categoryPurchaseSummary: {},
  defaultMerchantValues: {},
  MerchantValidate: 0,
  validMerchant: 0,
  responseStatus: "",
  statusValue: "",
  orders: [],
  orderProducts: [],
  orderManufacturs: [],
  profile: {},
  myCovertrusProgram: null,
  companies: [],
  companyWishLists: [],
  activeCompany: {},
  companyUpdateFlag: 0,
  notifications: [],
  popupNotification: {},
  notificationCount: 0,
  myProducts: [],
  manufacturerFromRebate: [],
  groupList: [],
  groupAreaManagerList: [],
  displayOptions: [
    { value: 0, label: "Default" },
    { value: 1, label: "Large" },
    { value: 2, label: "List" },
    { value: 3, label: "Wide" },
  ],
  cartItemsCount: 0,
  showAddToCartPopup: false,
  bucks: null,
  vendorConnectPopup: false,
  vendorConnectDetails: null,
  invalidCredentials: false,
  tokenExpired: false,
  errorMessage: "",
  budgets: [],
  savingsPopup: false,
  savingsPopupDetails: null,
  samplesPopUp: false,
  samplePopUpDetails: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // save Profile
    case PROFILE:
      let profileData = action.payload;
      return {
        ...state,
        profile: profileData,
      };
    case LOGOUT:
      return {
        state: INITIAL_STATE,
      };
    // save Company
    case COMPANIES:
      let companyData = action.payload;
      return {
        ...state,
        companies: companyData,
      };
    case COMPANY_WISH_LIST:
      return {
        ...state,
        companyWishLists: action.payload,
      };
    case ADD_FAVORITE_ITEM:
      return {
        ...state,
        companyWishLists: [...state.companyWishLists, action.payload],
      };
    case DELETE_FAVORITE_ITEM:
      return {
        ...state,
        companyWishLists: state.companyWishLists.filter(
          (el) => el != action.payload
        ),
      };
    case UPDATE_FAVORITE_ITEM:
      const foundIndex = state.companyWishLists.findIndex(
        (el) => el === action.payload.previousName
      );
      const newWishList = [...state.companyWishLists];
      newWishList[foundIndex] = action.payload.name;
      return {
        ...state,
        companyWishLists: newWishList,
      };
    case BUDGETS:
      return {
        ...state,
        budgets: action.payload,
      };
    case ACTIVE_COMPANY:
      let company = action.payload;
      return {
        ...state,
        activeCompany: company,
      };
    case COMPANY_UPDATE_FLAG:
      return {
        ...state,
        companyUpdateFlag: state.companyUpdateFlag + 1,
      };
    case GROUP_AREA_MANAGERS:
      let managers = action.payload;
      return {
        ...state,
        groupAreaManagerList: managers,
      };
    case NOTIFICATIONS:
      let notifications = action.payload;
      return {
        ...state,
        notifications: notifications,
      };
    case SHOW_ADD_TO_CART_POPUP:
      return {
        ...state,
        showAddToCartPopup: action.payload,
      };
    case SHOW_VENDOR_CONNECT_POPUP:
      return {
        ...state,
        vendorConnectPopup: true,
        vendorConnectDetails: action.payload,
      };
    case HIDE_VENDOR_CONNECT_POPUP:
      return {
        ...state,
        vendorConnectPopup: false,
        vendorConnectDetails: null,
      };
    case SHOW_SAVINGS_POPUP:
      return {
        ...state,
        savingsPopup: true,
        savingsPopupDetails: action.payload,
      };
    case HIDE_SAVINGS_POPUP:
      return {
        ...state,
        savingsPopup: false,
        savingsPopupDetails: null,
      };
    case SET_INVALID_CREDENTIALS:
      return {
        ...state,
        invalidCredentials: action.payload,
      };
    case SET_EXPIRED_TOKEN:
      return {
        ...state,
        tokenExpired: action.payload?.tokenExpired,
        errorMessage: action.payload?.errorMessage,
      };
    case POPUP_NOTIFICATION:
      let popupNotification = action.payload;
      return {
        ...state,
        popupNotification: popupNotification,
      };
    case NOTIFICATION_COUNT:
      let count = action.payload;
      return {
        ...state,
        notificationCount: count,
      };
    // add product to cart
    case ADD_TO_CART:
      return {
        ...state,
      };

    case ADD_TO_WISHLIST:
      let wishlistItems = action.payload;
      return {
        ...state,
        wishlist: wishlistItems,
      };
    case REMOVE_FROM_WISHLIST:
      // let wishlistItems = action.payload;
      // }
      return {
        ...state,
        // wishlist: wishlistItems
      };

    // update product item quantity
    case UPDATE_PRODUCT_QUANTITY:
      let newData = action.payload.cartItem;
      let newCartData = [];
      for (const cartItem of state.cart) {
        if (cartItem.productId === newData.productId) {
          cartItem.quantity = action.payload.newQuantity;
          cartItem.totalPrice =
            cartItem.productPrice * action.payload.newQuantity;
        }

        newCartData.push(cartItem);
      }
      return {
        ...state,
        cart: newCartData,
        totalPrice: state.totalPrice,
      };

    // display wishlist items
    case DISPLAY_WISHLIST_ITEMS:
      let wishList = action.payload;
      return {
        ...state,
        wishlist: wishList,
      };

    // display my products items
    case DISPLAY_MY_PRODUCTS_ITEMS:
      let products = action.payload;
      return {
        ...state,
        myProducts: products,
      };

    // display cart items
    case CARTITEMS:
      return {
        ...state,
        cartItemsCount: action.payload,
      };
    case DISPLAY_CART_ITEMS:
      return {
        ...state,
        cart: action.payload,
        // total: action.data
      };
    // display merchant cart items
    case DISPLAY_MERCHANT_CART_ITEMS:
      let merchantCartItems = action.payload;
      return {
        ...state,
        merchantCart: merchantCartItems,
      };

    case ORDER_HISTORY:
      return {
        ...state,
        orders: action.payload,
      };
    case PRODUCT_ORDER_HISTORY:
      return {
        ...state,
        orderProducts: action.payload,
      };

    case MANUFACURE_ORDER_HISTORY:
      ``;
      return {
        ...state,
        orderManufacturs: action.payload,
      };

    // remove product to cart
    case REMOVE_FROM_CART:
      // return state
      let { cart, removeItem } = action.payload;
      if (cart !== null) {
        let updatedCart = cart.filter(
          (cartItem) => cartItem.productId !== removeItem.productId
        );
        var totalData = 0;
        updatedCart && updatedCart.length > 0
          ? updatedCart.map((cartItem, index) => {
              totalData =
                cartItem.productMerchantPrices[0].priceBigDecimal + totalData;
            })
          : [];
        return {
          ...state,
          // cart: updatedCart,
          total: totalData,
        };
      } else {
        let updatedCart = [];
        return {
          ...state,
          // cart: updatedCart,
          // totalPrice: totalPrice - removeItem.price
        };
      }

    case UPDATE_CART:
      let { data, priceObj, cartList } = action.payload;
      let newCart = [];
      for (const cartItem of cartList) {
        if (cartItem.productId === data.defaultProductId) {
          cartItem.productPrice = priceObj.priceBigDecimal;
          cartItem.merchantId = priceObj.merchantId;
          cartItem.totalPrice = priceObj.priceBigDecimal * cartItem.quantity;
        }
        newCart.push(cartItem);
      }

      return {
        ...state,
        cart: newCart,
        totalPrice: state.totalPrice,
      };
    case SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    // vendor list
    case SHOW_VENDOR_LIST:
      let vendors = action.payload;
      return {
        ...state,
        vendorList: vendors,
      };
    case GET_MYCOVETRUS_DATA:
      return {
        ...state,
        myCovertrusProgram: action.payload,
      };

    // vendor list
    case SHOW_GROUP_LIST:
      let groups = action.payload;
      return {
        ...state,
        groupList: groups,
      };

    // DEFAULT_MERCHANT_DETAILS
    case DEFAULT_MERCHANT_DETAILS:
      let merchantDetail = action.payload;

      return {
        ...state,
        defaultMerchantValues: merchantDetail,
      };

    // SAVE_MERCHANT_DETAILS
    case SAVE_MERCHANT_DETAILS:
      let checkStatus = action.payload;
      let requestStatus = action.status;

      return {
        ...state,
        validMerchant: checkStatus,
        statusValue: requestStatus,
      };

    case BUCKS:
      return {
        ...state,
        bucks: action.payload,
      };

    // UPDATE_MERCHANT_DETAILS
    case UPDATE_MERCHANT_DETAILS:
      let merchantValidate = action.payload;
      let userStatus = action.status;
      return {
        ...state,
        MerchantValidate: merchantValidate,
        responseStatus: userStatus,
      };

    case MANUFACTURER_PURCHASE_SUMMARY_REPORT:
      let manufacturerPurchaseSummary = action.payload;
      return {
        ...state,
        manufacturerPurchaseSummary: manufacturerPurchaseSummary,
      };
    case MERCHANT_PURCHASE_SUMMARY_REPORT:
      let merchantPurchaseSummary = action.payload;
      return {
        ...state,
        merchantPurchaseSummary: merchantPurchaseSummary,
      };
    case PRODUCT_PURCHASE_SUMMARY_REPORT:
      let productPurchaseSummary = action.payload;
      return {
        ...state,
        productPurchaseSummary: productPurchaseSummary,
      };
    case CATEGORY_PURCHASE_SUMMARY_REPORT:
      let categoryPurchaseSummary = action.payload;
      return {
        ...state,
        categoryPurchaseSummary: categoryPurchaseSummary,
      };
    case MANUFACTURER_FROM_REBATE:
      let manufacturerFromRebate = action.payload;
      return {
        ...state,
        manufacturerFromRebate: manufacturerFromRebate,
      };

    // default case
    default:
      return { ...state };
  }
};
