import React from "react";

import { local } from "../../../constant";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  updateCart,
  logError,
  showVendorConnectPopup,
} from "../../../actions/action";
import { connect } from "react-redux";
import { Typography, Button } from "@material-ui/core";
import points from "../../../assets/images/points-new.png";
import pointsWithdrawal from "../../../assets/images/points-withdrawal.png";
import { HtmlTooltip } from "../html-tool-tip";
import { getProductPriceService } from "../../../services";
import { ICONS } from "../../../constants/icons";
import { TEXTS } from "../../../constants/texts";
import { formattedPrice } from "../../../util/utils";

class ProductPriceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
  }
  state = {
    price: null,
    priceRefresh: false,
    productId: null,
    defaultProductId: null,
    storeName: "",
    merchantUserId: "",
    login: false,
    defaultPrice: "",
    priceObj: { priceBigDecimal: null },
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    this.mounted = true;
    this.setState(
      {
        productId: this.props.pricingDetail.productId,
        defaultProductId: this.props.defaultProductId,
      },
      () => {
        this.getProductPrice();
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.pricingDetail.productId !== this.props.pricingDetail.productId
    ) {
      this.setState(
        {
          productId: nextProps.pricingDetail.productId,
          defaultProductId: nextProps.defaultProductId,
        },
        () => {
          this.getProductPrice();
        }
      );
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getProductPrice() {
    this.setState({ price: null });
    let { productId, defaultProductId } = this.state;
    if (productId || this.props.isAdmin) {
      getProductPriceService(productId, defaultProductId)
        .then((res) => {
          const { data } = res;
          if (data) {
            this.setState({ priceObj: data });

            if (data.refresh) {
              this.setState({ priceRefresh: true });
            }
            if (data.login) {
              this.setState({ login: true });
            }
            this.setState({ price: data.price });
            this.setState({ defaultPrice: data.defaultPrice });
            if (data.storeName) {
              this.setState({ storeName: data.storeName });
            }
            if (data.merchantUserId) {
              this.setState({ merchantUserId: data.merchantUserId });
            }
            if (this.props.merchantArr) {
              var idPresent = false;
              this.props.merchantArr.map((item, i) => {
                if (data.productId === item.productId) {
                  idPresent = true;
                }
              });
              if (!idPresent && this.props.merchantcartId == data.cartId) {
                this.props.merchantArr.push(data);
              }

              if (
                this.props.selectedMerchant == data.merchantId &&
                this.props.selectedProduct == data.productId
              ) {
                this.props.updateMerchantArr(
                  data,
                  true,
                  this.props.firstLoaded
                );
              } else {
                this.props.updateMerchantArr(
                  data,
                  false,
                  this.props.firstLoaded
                );
              }

              if (this.props.updateOrderOnline) {
                this.props.updateOrderOnline(this.props.index, data);
              }
            }
          }
        })
        .catch((error) =>
          this.setState(
            {
              error,
            },
            () => {
              this.props.logError(error.response);
            }
          )
        );
    }
  }

  getSelectedPrice = (priceObj) => {
    this.props.updateMerchantArr(priceObj, true, this.props.firstLoaded, true);
  };
  quantityMultiplication = (multiple, minQuantity) => {
    let quantity = 1;
    if (multiple > minQuantity) {
      quantity = multiple;
    } else {
      quantity = minQuantity;
    }
    return quantity;
  };
  renderPromotionDetails = (promotionData) => {
    return (
      <HtmlTooltip
        maxWidth={1300}
        title={
          promotionData.promotionDatas &&
          promotionData.promotionDatas.length > 0
            ? promotionData.promotionDatas.map((promotionData, i) => {
                return (
                  <div key={i}>
                    <Typography gutterBottom>
                      <h5>{promotionData.title}</h5>
                    </Typography>

                    <Typography gutterBottom>
                      <div className="short-desc">
                        <p>{promotionData.description}</p>
                      </div>
                    </Typography>
                    <Typography gutterBottom>
                      <p>
                        <b>Promotion Expires:{promotionData.toDate} </b>
                      </p>
                    </Typography>
                  </div>
                );
              })
            : ""
        }
        placement="top"
      >
        <img
          src={local.imageUrl + promotionData.promotionImageUrl}
          alt="promotion"
        />
      </HtmlTooltip>
    );
  };
  checkPromotionDetails = (pricingDetail, priceObj) => {
    if (priceObj.promotion) {
      return this.renderPromotionDetails(priceObj);
    } else if (pricingDetail.promotion) {
      return this.renderPromotionDetails(pricingDetail);
    } else {
      return;
    }
  };
  render() {
    const {
      pricingDetail,
      index,
      length,
      productDetails,
      singlePrice,
      selectedMerchant,
      selectedProduct,
      appAttributes,
    } = this.props;
    const { priceObj, price, login } = this.state;
    let bundledPrice = null;
    if (
      priceObj?.productCartConfigModel?.multiple > 0 &&
      priceObj?.productCartConfigModel?.minimumQuantity > 0
    ) {
      let priceValue =
        priceObj.priceBigDecimal *
        this.quantityMultiplication(
          priceObj?.productCartConfigModel?.multiple,
          priceObj?.productCartConfigModel?.minimumQuantity
        );
      if (!isNaN(priceValue)) {
        bundledPrice = ` ${formattedPrice(priceValue.toFixed(2))}`;
      }
    }
    let condition = false;
    if (
      selectedMerchant != null &&
      priceObj &&
      priceObj.priceBigDecimal &&
      selectedProduct != null
    ) {
      condition =
        productDetails.selectedMerchant == priceObj.merchantId &&
        productDetails.merchantProduct == priceObj.productId;
    } else {
      if (
        !singlePrice &&
        productDetails.samePriceFlag &&
        productDetails.lowestPrice === priceObj.priceBigDecimal &&
        productDetails.productPrice === priceObj.priceBigDecimal
      ) {
        condition =
          productDetails.productPrice === priceObj.priceBigDecimal &&
          productDetails.defaultMerchantId == priceObj.merchantId;
      } else {
        condition =
          productDetails.productPrice === priceObj.priceBigDecimal &&
          productDetails.merchantId === priceObj.merchantId &&
          productDetails.merchantProduct == priceObj.productId;
      }
    }
    return (
      <HtmlTooltip
        title={
          pricingDetail && pricingDetail.serverDown
            ? "This vendor is facing some technical issues"
            : ""
        }
        placement="top"
      >
        <div
          key={index}
          style={{
            display: "flex",
            paddingTop: "10px",
          }}
        >
          <div style={{ width: "200px", height: "45px" }}>
            <img
              src={pricingDetail.merchantLogo}
              alt={pricingDetail.merchantName}
            />
            {priceObj.preferred &&
            appAttributes &&
            appAttributes.PREFERRED_IMAGE_URL ? (
              <HtmlTooltip
                title={appAttributes.PREFERRED_FILTER_LABEL}
                placement="top"
                style={{
                  visibility: "visible",
                }}
              >
                <img
                  src={appAttributes.PREFERRED_IMAGE_URL}
                  alt=""
                  style={{
                    width: "40%",
                    padding: "8px",
                    visibility: "visible",
                  }}
                />
              </HtmlTooltip>
            ) : (
              ""
            )}
          </div>

          <div
            style={{
              width: "15%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              visibility: pricingDetail.manufacturer ? "visible" : "hidden",
            }}
          >
            <span style={{ fontWeight: "bold" }}>
              {pricingDetail.manufacturer}
            </span>
          </div>

          {pricingDetail && !pricingDetail.login ? (
            <>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{ height: "80%" }}
                  className="connect-button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.props.showVendorConnectPopup(pricingDetail);
                  }}
                >
                  Connect
                </Button>
              </div>
              <div className="d-flex "></div>
              <div
                style={{
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></div>
              <div
                style={{
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></div>
              <div
                style={{
                  width: "14%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  visibility: productDetails.points ? "visible" : "hidden",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  visibility: productDetails.pointRedeem ? "visible" : "hidden",
                }}
              ></div>

              <div style={{ display: "flex" }}></div>
            </>
          ) : (
            <>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {price && price !== null ? (
                  login ? (
                    priceObj && !priceObj.orderable ? (
                      <div>
                        {!singlePrice &&
                        !productDetails.samePriceFlag &&
                        productDetails.lowestPrice ===
                          priceObj.priceBigDecimal ? (
                          <span
                            style={{
                              fontWeight: "600",
                              //  backgroundColor: "yellow"
                            }}
                          >
                            --
                            {/* {defaultPrice} */}
                          </span>
                        ) : (
                          <span style={{ fontWeight: "600" }}>
                            --
                            {/* {defaultPrice} */}
                          </span>
                        )}
                      </div>
                    ) : !singlePrice &&
                      !productDetails.samePriceFlag &&
                      productDetails.lowestPrice ===
                        priceObj.priceBigDecimal ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          flexDirection: "column",
                          padding: "0px 2px",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: priceObj.standardPrice
                              ? "bold"
                              : "normal",
                            color: "#2c2828",
                            // , backgroundColor: "yellow"
                          }}
                        >
                          {bundledPrice ? bundledPrice : price}
                        </span>
                        {priceObj.standardPrice ? (
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "normal",
                              color: "#ACAAAA",
                              textDecoration: "line-through 1px",
                              textDecorationColor: "hsl(2deg 60% 54%)",
                              marginTop: "2px",
                            }}
                          >
                            {priceObj.standardPrice
                              ? priceObj.standardPrice
                              : ""}{" "}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          flexDirection: "column",
                          padding: "0px 2px",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: priceObj.standardPrice
                              ? "bold"
                              : "normal",
                            fontSize: "15px",
                            color: "#2c2828",
                            textDecoration: "none",
                          }}
                        >
                          {bundledPrice ? bundledPrice : price}
                        </span>
                        {priceObj.standardPrice ? (
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "normal",
                              color: "#ACAAAA",
                              textDecoration: "line-through 1px",
                              textDecorationColor: "hsl(2deg 60% 54%)",
                              marginTop: "2px",
                            }}
                          >
                            {priceObj.standardPrice
                              ? priceObj.standardPrice
                              : ""}{" "}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    )
                  ) : (
                    <div>
                      {!singlePrice &&
                      !productDetails.samePriceFlag &&
                      productDetails.lowestPrice ===
                        priceObj.priceBigDecimal ? (
                        <span
                          style={{
                            fontWeight: "600",
                            // , backgroundColor: "yellow"
                          }}
                        >
                          --
                          {/* {defaultPrice} */}
                        </span>
                      ) : (
                        <span style={{ fontWeight: "600" }}>
                          --
                          {/* {defaultPrice} */}
                        </span>
                      )}
                    </div>
                  )
                ) : (
                  <div style={{ marginTop: "6px" }}>
                    <CircularProgress size={20} />{" "}
                  </div>
                )}
              </div>
              <div
                style={{
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <HtmlTooltip
                  title={
                    priceObj.stockInventoryMessage
                      ? priceObj.stockInventoryMessage
                      : priceObj.stockImage?.indexOf("stock-gray.png") > -1
                      ? "Unknown"
                      : ""
                  }
                  placement="top"
                >
                  <span>
                    {priceObj.stockImage ? (
                      <img
                        style={{ cursor: "pointer" }}
                        src={local.imageUrl + priceObj.stockImage}
                        alt={priceObj.stockStatus}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </HtmlTooltip>
              </div>

              <div
                style={{
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {this.checkPromotionDetails(pricingDetail, priceObj)}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  visibility: productDetails.points ? "visible" : "hidden",
                }}
              >
                <HtmlTooltip
                  title={
                    pricingDetail.productPointModel
                      ? pricingDetail.productPointModel.message
                      : ""
                  }
                  placement="top"
                >
                  <span>
                    {pricingDetail.productPointModel ? (
                      <img src={points} alt="" style={{ width: "64%" }} />
                    ) : (
                      <img
                        src={points}
                        alt=""
                        style={{ width: "64%", visibility: "hidden" }}
                      />
                    )}
                  </span>
                </HtmlTooltip>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  visibility: productDetails.pointRedeem ? "visible" : "hidden",
                }}
              >
                <HtmlTooltip
                  title={
                    pricingDetail.redeemProductPointModel
                      ? pricingDetail.redeemProductPointModel.message
                      : ""
                  }
                  placement="top"
                >
                  <span>
                    {pricingDetail.redeemProductPointModel ? (
                      <img
                        src={pointsWithdrawal}
                        alt=""
                        style={{ width: "64%" }}
                      />
                    ) : (
                      <img
                        src={pointsWithdrawal}
                        alt=""
                        style={{ width: "64%", visibility: "hidden" }}
                      />
                    )}
                  </span>
                </HtmlTooltip>
              </div>
              <div className="d-flex ">
                {condition && pricingDetail.greenshippingProduct ? (
                  <HtmlTooltip
                    title={`${
                      pricingDetail.selectedGreenShippedProduct
                        ? TEXTS.GREEN_SHIP
                        : TEXTS.DISABLED_GREEN_SHIP
                    }`}
                  >
                    <button
                      onClick={() => this.props.setGreenShipFlag(index)}
                      style={{ backgroundColor: "white", border: "none" }}
                    >
                      <div className="d-flex">
                        {/* <i className={`shipping-checkbox ${pricingDetail?.selectedGreenShippedProduct ? "selected-checkbox" : "checkbox"} material-icons`} >{pricingDetail?.selectedGreenShippedProduct ? "check_box" : "check_box_outline_blank"}</i> */}
                        <img
                          className="cursor-pointer"
                          src={
                            pricingDetail?.selectedGreenShippedProduct
                              ? ICONS.GREEN_SHIP_CHECKED
                              : ICONS.DISABLED_GREEN_SHIP_CHECKED
                          }
                          alt={"Greenship"}
                        />
                      </div>
                    </button>
                  </HtmlTooltip>
                ) : (
                  <HtmlTooltip
                    title={`${
                      pricingDetail.selectedGreenShippedProduct
                        ? TEXTS.GREEN_SHIP
                        : TEXTS.DISABLED_GREEN_SHIP
                    }`}
                  >
                    <button
                      onClick={() => this.props.setGreenShipFlag(index)}
                      style={{
                        backgroundColor: "white",
                        border: "none",
                        visibility: "hidden",
                      }}
                    >
                      <div className="d-flex">
                        {/* <i className={`shipping-checkbox ${pricingDetail?.selectedGreenShippedProduct ? "selected-checkbox" : "checkbox"} material-icons`} >{pricingDetail?.selectedGreenShippedProduct ? "check_box" : "check_box_outline_blank"}</i> */}
                        <img
                          className="cursor-pointer"
                          src={
                            pricingDetail?.selectedGreenShippedProduct
                              ? ICONS.GREEN_SHIP_CHECKED
                              : ICONS.DISABLED_GREEN_SHIP_CHECKED
                          }
                          alt={"Greenship"}
                        />
                      </div>
                    </button>
                  </HtmlTooltip>
                )}
              </div>
              <div style={{ display: "flex" }}>
                {length > 1 ? (
                  priceObj &&
                  priceObj.orderable &&
                  pricingDetail.availableModel &&
                  pricingDetail.availableModel.canOrderOnline == true ? (
                    <button
                      onClick={() => this.getSelectedPrice(priceObj)}
                      style={{ backgroundColor: "white", border: "none" }}
                    >
                      <i
                        className={`${
                          condition ? "selected-checkbox" : "checkbox"
                        } material-icons`}
                      >
                        {condition ? "check_box" : "check_box_outline_blank"}
                      </i>
                    </button>
                  ) : (
                    <HtmlTooltip
                      title={
                        pricingDetail.availableModel
                          ? pricingDetail.availableModel.message
                          : ""
                      }
                      placement="top"
                    >
                      <button
                        style={{ backgroundColor: "white", border: "none" }}
                      >
                        <i className={`disabled-checkbox material-icons`}>
                          check_box_outline_blank
                        </i>
                      </button>
                    </HtmlTooltip>
                  )
                ) : (
                  <button
                    onClick={() => this.getSelectedPrice(priceObj)}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      visibility: "hidden",
                    }}
                  >
                    <i
                      className={`${
                        condition ? "selected-checkbox" : "checkbox"
                      } material-icons`}
                    >
                      {condition ? "check_box" : "check_box_outline_blank"}
                    </i>
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </HtmlTooltip>
    );
  }
}

const mapStateToProps = ({ appSettings }) => {
  const { appAttributes } = appSettings;
  return { appAttributes };
};

export default connect(mapStateToProps, {
  updateCart,
  logError,
  showVendorConnectPopup,
})(ProductPriceComponent);
