import {get, getNoAuth, post, postNoAuth, put} from './../request'
import * as ApiUrls from './apiUrls'

export const getCustomerService = () => {
    return get({
        url: ApiUrls.GET_CUSTOMER()
    })
}

export const loginCustomerService = (loginObj) => {
    return postNoAuth({
        url: ApiUrls.CUSTOMER_LOGIN(),
        data: JSON.stringify(loginObj)
    })
}

export const unlockCustomerService = () => {
    return put({
        url: ApiUrls.CUSTOMER_UNLOCK()        
    })
}
export const getSupplierThirdPartyUrlService = (supplierId) => {
    return get({
        url: ApiUrls.GET_SUPPLIER_URL(supplierId)
    })
}

export const forgotPasswordService = (email) => {
    return get({
        url: ApiUrls.CUSTOMER_FORGOT_PASSWORD(email)
    })
}
export const thirdPartyLoginService = (accessToken) => {
    return get({
        url: ApiUrls.THIRD_PARTY_LOGIN(accessToken)
    })
}