export function getAccessToken() {
    let token = "";
    if (sessionStorage.getItem("token")) {
        token = JSON.parse(sessionStorage.getItem("token"));
    }
    if (localStorage.getItem("tempToken")) {
        token = localStorage.getItem("tempToken");
    }
    return token;
}

export function getCompanyDefaultGroup() {
    let token = "";
    if (sessionStorage.getItem("companyDefaultGroup")) {
        token = JSON.parse(sessionStorage.getItem("companyDefaultGroup"));
    }
    if (localStorage.getItem("companyDefaultGroup")) {
        token = localStorage.getItem("companyDefaultGroup");
    }
    return token;
}

export function getAdditionalHeaders() {
    let additionalHeaders = {};
    if ((sessionStorage.getItem("support") && sessionStorage.getItem("support") == "true") ||
        (sessionStorage.getItem("groupAreaManager") && sessionStorage.getItem("groupAreaManager") == "true")) {
        if (sessionStorage.getItem("id") && sessionStorage.getItem("companyId")) {
            additionalHeaders["groupId"] = sessionStorage.getItem("groupId") && sessionStorage.getItem("groupId") != "null" ? sessionStorage.getItem("groupId") : 0;
            additionalHeaders["customerId"] = sessionStorage.getItem("id") && sessionStorage.getItem("id") != "null" ? sessionStorage.getItem("id") : 0;
            additionalHeaders["companyId"] = sessionStorage.getItem("companyId") && sessionStorage.getItem("companyId") != "null" ? sessionStorage.getItem("companyId") : 0;
            return { "additional-details": JSON.stringify(additionalHeaders) };
        }
    }
    return null;
}
