/* eslint-disable */
import React from 'react';
import './cart.css';
// import { Button } from '@material-ui/core';
import { Dialog } from 'primereact/dialog';
import { Button, Grid, TextField } from '@material-ui/core';
import 'primereact/resources/primereact.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { saveMerchantDetails, showAlert } from "../../../actions/action";
import ContentLoader from '../loaders/ContentLoader';
// import Select from 'react-select';
// import { InputText } from 'primereact/inputtext';
// import ConfirmationPopup from '../../../components/global/confirmation-popup';
// import { local } from "../../../constant";
// import { getAccessToken } from "../../../Authorization";
import { connect } from 'react-redux';



class VendorConnect extends React.Component {

   constructor(props) {
      super(props);
      this.confirmationDialog = React.createRef();
      this.state = {
         loading: false,
         quantityTextVisible: false,
         vendorObj: null
      };
   }

   componentDidMount() {
      this.setState({ vendorObj: this.props.vendor });
   }

   submitDetails = (vendorObj) => {
      if (vendorObj.merchantUserName && vendorObj.merchantPassword) {
         this.setState({ loading: true });
         const postObj = {
            merchantUserName: vendorObj.merchantUserName,
            merchantPassword: vendorObj.merchantPassword
         };
         postObj['storeId'] = vendorObj.merchantId;
         this.props.saveMerchantDetails(postObj).then((response) => {
            if (response.status !== 200) {
               this.setState({ loading: false });
               return;
            } else {
               return response.json();
            }
         }).then((data) => {
            if (data && data.status) {
               this.props.showAlert("Successfully Saved!!!", "success");
               window.location.reload();
            } else {
               this.props.showAlert("Username or Password is incorrect", "error");
            }
            this.setState({ loading: false });
         })
      } else {
         this.props.showAlert("Please enter Username and Password", "error");
         return;
      }

   };

   handleChange = (e) => {
      let { vendorObj } = this.state;
      vendorObj[e.target.name] = e.target.value;
      if (e.target.name === "merchantUserName") {
         vendorObj[e.target.name] = vendorObj[e.target.name]?.trim()
      }
      this.setState({ vendorObj });
   }
   render() {
      const { show } = this.props;
      const { vendorObj, loading } = this.state;
      return (
         <div>
            <div className="card">
               <Dialog className="main-dialogue" header="Vendor Connect" visible={show} style={{ width: '50vw' }}
                  position="top" onHide={this.props.hidePopup}
               >
                  {vendorObj ?
                     <div style={{ marginBottom: '1rem' }}>
                        <div>
                           <Grid container spacing={32}>
                              <Grid item xs={12} sm={12} md={3} lg={3} >
                                 <div className="d-flex" >
                                    <img style={{ cursor: 'n-resize', margin: 'auto' }} src={vendorObj.merchantLogo} alt={vendorObj.storeName}
                                    />
                                 </div>

                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3} >
                                 <div className="d-flex">
                                    <TextField
                                       style={{ width: '80%' }}
                                       id="standard-email"
                                       label={"Username"}
                                       className="iron-form-input-wrap"
                                       type={"text"}
                                       value={vendorObj.merchantUserName ? vendorObj.merchantUserName : ''}
                                       onChange={(e) => { this.handleChange(e) }}
                                       name="merchantUserName"
                                    />
                                 </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3} >
                                 <TextField className="text-field iron-form-input-wrap"
                                    id="standard-password-input"
                                    label={"Password"}
                                    autoComplete="false"
                                    type="password"
                                    name="merchantPassword"
                                    onChange={(e) => { this.handleChange(e) }}
                                 />
                              </Grid>

                              <Grid item xs={12} sm={12} md={3} lg={3} >
                                 <Button
                                    onClick={() => { this.submitDetails(vendorObj) }}
                                    className="button btn-active"
                                 >
                                    {'Connect'}
                                 </Button>

                              </Grid>
                           </Grid>
                        </div>
                     </div>
                     : ""}
               </Dialog>
            </div>
            {loading ?
               <ContentLoader />
               : ""}

            {/* <ConfirmationPopup
               ref={this.confirmationDialog}
               onConfirm={(res) => this.deleteCartItem(res)}
               msg="Do you want to remove this product from your cart?"
            /> */}

         </div >
      );
   }
}
export default connect(null, { saveMerchantDetails, showAlert })(VendorConnect);
