/**
 *  admin sidebar menu
 */
/* eslint-disable */
export default [
  {
    menu_title: "Jobs",
    icon: "recent_actors",
    child_routes: [
      {
        path: "/admin-panel/admin/job/list",
        menu_title: "List",
        icon: "settings",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/job/lastOrderHistory",
        menu_title: "Order History",
        icon: "settings",
        child_routes: null,
      },
    ],
  },
  {
    menu_title: "Category Mapping",
    path: "/admin-panel/admin/categoryMapping",
    icon: "recent_actors",
    child_routes: null,
  },
  {
    menu_title: "Manufacturer Mapping",
    path: "/manufacturerMapping",
    icon: "pages",
    child_routes: [
      {
        path: "/admin-panel/admin/manufacturerMappingSingle/edit",
        menu_title: "Edit",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/manufacturerMappingSingle/map",
        menu_title: "Map",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/manufacturerMappingSingle/un-map",
        menu_title: "Un-Map",
        icon: "arrow_right_alt",
        child_routes: null,
      },
    ],
  },
  {
    menu_title: "Product Mapping",
    path: "/productMapping",
    icon: "pages",
    child_routes: [
      {
        path: "/admin-panel/admin/productMappingSingle/map",
        menu_title: "Map",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/productMappingSingle/un-map",
        menu_title: "Un-Map",
        icon: "arrow_right_alt",
        child_routes: null,
      },
    ],
  },
  {
    menu_title: "Practices",
    path: "/admin-panel/admin/practice",
    icon: "work",
    child_routes: null,
  },
  {
    menu_title: "Customers",
    path: "/admin-panel/admin/customer",
    icon: "work",
    child_routes: null,
  },
  {
    menu_title: "White Labels",
    path: "/admin-panel/admin/whitelabel",
    icon: "work",
    child_routes: null,
  },
  {
    menu_title: "Groups",
    path: "/admin-panel/admin/group/list",
    icon: "pages",
    child_routes: null,
  },
  {
    menu_title: "Vendors",
    path: "/admin-panel/admin/vendors",
    icon: "store",
    child_routes: null,
  },
  {
    menu_title: "Programs",
    path: "/admin-panel/admin/program",
    icon: "store",
    child_routes: null,
  },
  {
    menu_title: "Products",
    path: "/product",
    icon: "pages",
    child_routes: [
      {
        path: "/admin-panel/admin/productMappingSingle/view",
        menu_title: "Enable/Disable",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/productMappingSingle/image",
        menu_title: "Image",
        icon: "arrow_right_alt",
        child_routes: null,
      },

      {
        path: "/admin-panel/admin/productMappingSingle/custom",
        menu_title: "Custom Products",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/productMappingSingle/create",
        menu_title: "Create",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/productMappingSingle/edit",
        menu_title: "Edit",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/ratingsAndReviews",
        menu_title: "Ratings And Reviews",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/config",
        menu_title: "Quantity config",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/productMappingSingle/alternate",
        menu_title: "Alternate",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/products/product-update",
        menu_title: "Product Refresh",
        icon: "arrow_right_alt",
        child_routes: null,
      },
    ],
  },
  {
    menu_title: "Orders",
    path: "/orders",
    icon: "pages",
    child_routes: [
      {
        path: "/admin-panel/admin/orders/placed",
        menu_title: "Placed Orders",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/orders/greenshipping",
        menu_title: "Green Shipped Orders",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/orders/discrepancy",
        menu_title: "Order Discrepancy",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/orders/practice/history",
        menu_title: "Practice Orders",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/orders/configuration",
        menu_title: "Order Configuration",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/orders/points",
        menu_title: "Points",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/orders/history",
        menu_title: "History",
        icon: "arrow_right_alt",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/order-status",
        menu_title: "Order Status Mapping",
        icon: "arrow_right_alt",
        child_routes: null,
        status_check: true,
      },
    ],
  },

  {
    menu_title: "Advertisements",
    path: "/admin-panel/admin/advertisements",
    icon: "settings",
    child_routes: null,
  },
  {
    menu_title: "Announcements",
    icon: "recent_actors",
    child_routes: [
      {
        path: "/admin-panel/admin/announcements/list",
        menu_title: "List",
        icon: "settings",
        child_routes: null,
      },
      {
        path: "/admin-panel/admin/announcements/add",
        menu_title: "Add",
        icon: "settings",
        child_routes: null,
      },
    ],
  },
  {
    menu_title: "Notifications",
    icon: "notifications",
    child_routes: [
      {
        path: "/admin-panel/admin/notifications/devices",
        menu_title: "Devices",
        icon: "devices",
        child_routes: null,
      },
    ],
  },
  // {
  //   menu_title: "System Configuration",
  //   path: "/admin-panel/admin/system-configuration",
  //   icon: "store",
  //   child_routes: null,
  // },
  {
    menu_title: "Release Notes",
    path: "/admin-panel/admin/release-notes",
    icon: "note",
    child_routes: null,
  },
  {
    menu_title: "FAQ",
    path: "/admin-panel/admin/faq",
    icon: "help",
    child_routes: null,
  },
];
