import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import IntlMessages from "../../../util/IntlMessages";
import { connect } from "react-redux";
import { local } from "../../../constant";
import { getAccessToken, getAdditionalHeaders } from "../../../Authorization";
import {
  getBugetList,
  showVendorList,
  getProfile,
  getNotifications,
  getBucks,
  showVendors,
  getMyCovertrusProgram,
} from "../../../actions/action";
import { Budget } from "../../global/budget";
import "./style.css";
const support = JSON.parse(sessionStorage.getItem("support"));

class HeaderMenu extends React.Component {
  state = {
    links: [],
    vendors: [],
    message: null,
    budgets: null,
  };

  componentDidMount() {
    this.props.showVendors();
    this.props.getMyCovertrusProgram();
    // this.props.showVendorList();
    this.getSystemMessage();
    this.props.getBugetList();
    this.props.getProfile();
    this.props.getNotifications();
    this.props.getBucks();
    this.setState({ links: this.props.links });
  }

  getSystemMessage = () => {
    var sessionData = getAccessToken();
    fetch(
      local.baseUrl + `api/v1/private/notification/type?type=SYSTEM_MESSAGE`,
      {
        method: "get",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + sessionData,
          ...getAdditionalHeaders(),
        }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          return;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data && data.length > 0) {
          this.setState({ message: data[0] });
        }
      });
  };
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.links != this.props.links ||
      nextProps.role !== this.props.role
    ) {
      this.setState({ links: nextProps.links });
    }
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  getBudgetName = (budget) => {
    let type = this.capitalizeFirstLetter(budget.type);
    return type;
  };

  convertHtmlText = (htmlText) => {
    htmlText = htmlText.replace("text-align:none", "text-align:center");
    return htmlText;
  };

  render() {
    const { links, message } = this.state;
    const { isFixedHeader, budgets } = this.props;
    return (
      <div className="horizontal-menu">
        <ul className="d-inline-block iron-header-menu mb-0">
          {/* {/ <li><a href="#"><i className="fa fa-search" /></a></li> /} */}
          {links.map((navLink, index) => {
            if (navLink.child_routes && navLink.child_routes !== null) {
              return (
                <li
                  key={index}
                  className={classnames({ "mega-menu": navLink.mega })}
                >
                  <NavLink to={navLink.path} activeClassName="link-active">
                    {/* {/ <a > /} */}
                    {/* {/ {navLink.menu_title} /} */}
                    {navLink.menu_title != null ? (
                      navLink.menu_title
                    ) : (
                      <i className="material-icons vertical-align-middle">
                        {navLink.icon}
                      </i>
                    )}
                    {/* {/ </a> /} */}
                  </NavLink>
                  {navLink.type && navLink.type === "subMenu" ? (
                    <Fragment>
                      {navLink.child_routes !== null && (
                        <ul className="sub-menu mb-0">
                          {navLink.child_routes &&
                            navLink.child_routes.map((subNavLink, index) => (
                              <Fragment key={index}>
                                {subNavLink.child_routes !== null &&
                                subNavLink.child_routes.length > 0 ? (
                                  <li>
                                    <NavLink
                                      to={navLink.path}
                                      activeClassName="link-active"
                                      className="d-flex justify-content-between align-items-center"
                                    >
                                      {subNavLink.menu_title}
                                      <i className="material-icons vertical-align-middle">
                                        {subNavLink.icon != null
                                          ? subNavLink.icon
                                          : "keyboard_arrow_right"}
                                      </i>
                                    </NavLink>
                                    <ul className="sub-menu-child mb-0">
                                      {subNavLink.child_routes.map(
                                        (subMenuItem, index) => (
                                          <li key={index}>
                                            <NavLink
                                              to={subMenuItem.path}
                                              activeClassName="link-active"
                                            >
                                              {subMenuItem.menu_title !=
                                              null ? (
                                                subMenuItem.menu_title
                                              ) : (
                                                <i className="material-icons vertical-align-middle">
                                                  {subMenuItem.icon}
                                                </i>
                                              )}
                                            </NavLink>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </li>
                                ) : (
                                  <li>
                                    <NavLink
                                      to={
                                        navLink.path === "/rebates" &&
                                        subNavLink.path === "/programs"
                                          ? `/userProfile${subNavLink.path}`
                                          : `${navLink.path}${subNavLink.path}`
                                      }
                                    >
                                      {/* {/ <Link to={subNavLink.path}> /} */}
                                      {subNavLink.menu_title != null ? (
                                        subNavLink.menu_title
                                      ) : (
                                        <i className="material-icons vertical-align-middle">
                                          {subNavLink.icon}
                                        </i>
                                      )}
                                    </NavLink>
                                  </li>
                                )}
                              </Fragment>
                            ))}
                        </ul>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {navLink.child_routes !== null && (
                        <ul className="sub-menu mb-0 d-flex">
                          {navLink.child_routes &&
                            Object.keys(navLink.child_routes).map(
                              (subNavLink, index) => (
                                <li key={index}>
                                  <a href={navLink.path}>
                                    <IntlMessages id={subNavLink} />
                                  </a>
                                  <ul className="sub-menu mb-0">
                                    {navLink.child_routes[subNavLink].map(
                                      (megaMenuItem, index) => (
                                        <li key={index}>
                                          <NavLink to={megaMenuItem.path}>
                                            <IntlMessages
                                              id={megaMenuItem.menu_title}
                                            />
                                          </NavLink>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              )
                            )}
                        </ul>
                      )}
                    </Fragment>
                  )}
                </li>
              );
            }
            return (
              <li key={index}>
                <NavLink to={navLink.path} activeClassName="link-active">
                  {/* {/ {navLink.menu_title} /} */}
                  {navLink.menu_title != null ? (
                    navLink.menu_title
                  ) : (
                    <i className="material-icons vertical-align-middle">
                      {navLink.icon}
                    </i>
                  )}
                </NavLink>
              </li>
            );
          })}
          {support ? (
            <li>
              <NavLink to={"/group-admin"} activeClassName="link-active">
                Group Admin
              </NavLink>
            </li>
          ) : (
            ""
          )}
        </ul>
        {!isFixedHeader && budgets && budgets.length ? (
          <div className="budget-dropdown">
            <div className="budget-text">{`Budget${
              budgets.length > 1 ? "s" : ""
            }`}</div>
            {
              <div className="budget-list">
                {budgets.map((budget, i) => {
                  if (i < 3) {
                    return (
                      <Budget
                        budget={budget}
                        budgets={budgets}
                        history={this.props.history}
                      />
                    );
                  }
                })}
              </div>
            }
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ ecommerce }) => {
  const { profile, vendorList, budgets, role } = ecommerce;
  return { profile, vendorList, budgets, role };
};

export default connect(mapStateToProps, {
  showVendorList,
  showVendors,
  getMyCovertrusProgram,
  getBugetList,
  getProfile,
  getNotifications,
  getBucks,
})(HeaderMenu);
