import * as React from "react";

function JobsSvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ marginRight: "10px" }}
    >
      <path
        d="M20.5945 7.875H3.40547C2.97422 7.875 2.625 8.21016 2.625 8.625V19.5C2.625 19.9148 2.97422 20.25 3.40547 20.25H20.5922C21.0234 20.25 21.3727 19.9148 21.3727 19.5V8.625C21.375 8.21016 21.0258 7.875 20.5945 7.875ZM8.4375 18.5625H4.3125V14.8125H8.4375V18.5625ZM8.4375 13.3125H4.3125V9.5625H8.4375V13.3125ZM14.0625 18.5625H9.9375V14.8125H14.0625V18.5625ZM14.0625 13.3125H9.9375V9.5625H14.0625V13.3125ZM19.6875 18.5625H15.5625V14.8125H19.6875V18.5625ZM19.6875 13.3125H15.5625V9.5625H19.6875V13.3125ZM21.1875 3.75H2.8125C2.70937 3.75 2.625 3.83437 2.625 3.9375V5.8125C2.625 5.91563 2.70937 6 2.8125 6H21.1875C21.2906 6 21.375 5.91563 21.375 5.8125V3.9375C21.375 3.83437 21.2906 3.75 21.1875 3.75Z"
        fill={props.color}
        fill-opacity="0.7"
      />
    </svg>
  );
}

export default JobsSvgComponent;
