export const local = {
   baseUrl: [process.env.SHOPPIZER_APP_ROUTER_BASE || '/'],
  // baseUrl : ['http://localhost:8089/'],
  // baseUrl: ["https://staging.vetscout.com/"],
  merchantCode: "DEFAULT",
  imageUrl: "https://vet-price-check.s3-us-west-2.amazonaws.com/",
  rollbarConfig:
    {
      accessToken: "49e1d4df105a45a6a1dcfb919052c233",
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: false,
      // enabled: process.env.REACT_APP_ENVIRONMENT === "production",
      payload: {
        environment: process?.env?.REACT_APP_ENVIRONMENT
          ? process?.env?.REACT_APP_ENVIRONMENT
          : "development",
      },
    }
  
};
