import * as React from "react";

const ReleaseNotesSvgComponent = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ marginRight: "10px" }}
  >
    <rect
      x="4"
      y="4"
      width="16"
      height="16"
      rx="2"
      ry="2"
      stroke={props.color || "black"}
      strokeWidth="1.5"
      fill="none"
    />
    <line
      x1="8"
      y1="9"
      x2="16"
      y2="9"
      stroke={props.color || "black"}
      strokeWidth="1.5"
    />
    <line
      x1="8"
      y1="13"
      x2="16"
      y2="13"
      stroke={props.color || "black"}
      strokeWidth="1.5"
    />
    <line
      x1="8"
      y1="17"
      x2="12"
      y2="17"
      stroke={props.color || "black"}
      strokeWidth="1.5"
    />
  </svg>
);

export default ReleaseNotesSvgComponent;
