import { useState, useEffect } from "react";
import { getAccessToken, getAdditionalHeaders } from "Authorization";
import { local } from "constant";

const useFetch = (url, options = {}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const sessionData = getAccessToken();

  useEffect(() => {
    const fetchData = async () => {
      if (!sessionData) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      const defaultHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `bearer ${sessionData}`,
        ...getAdditionalHeaders(),
      };

      try {
        const response = await fetch(`${local.baseUrl}${url}`, {
          ...options,
          headers: {
            ...defaultHeaders,
            ...options.headers,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error);
        console.error("Fetch error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sessionData, url, JSON.stringify(options)]);

  return { data, isLoading, error };
};

export default useFetch;
