import * as React from "react"

function PointBuckSvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 259 304" {...props}>
      <path fill="#fff" d="M0 0h259v304H0z" />
      <path fill={props.active} d="M31.8 304H0V0h237.3z" />
    </svg>
  )
}

export default PointBuckSvgComponent
