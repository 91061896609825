/**
 * Content loader
 */
/* eslint-disable */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const ContentLoader = (props) => (
   <div className={props.defaultCss && 'iron-progress-bar d-flex justify-content-center align-items-center'} >

      < CircularProgress size={props.size} thickness={props.thickness} style={{ color: "var(--active)" }} />
   </div >
);
ContentLoader.defaultProps = {
   size: 70,
   thickness: 1.5,
   defaultCss: true
};
export default ContentLoader;