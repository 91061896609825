import React from "react";
import { HtmlTooltip } from "components/global/html-tool-tip";
import useFetch from "hooks/useFetch";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import "./TopFixedMessage.scss";

const CUSTOMER_ROLE = "CUSTOMER";

const TopFixedMessage = ({ showTooltip = false }) => {
  const role = useSelector((state) => state.ecommerce.role);
  const { data: messageData, isLoading } =
    useFetch(`api/v1/private/notification/type?type=SYSTEM_MESSAGE`);
  const messageContent = (messageData && messageData[0].content) || "";

  return role === CUSTOMER_ROLE && !isLoading && messageData ? (
    <div data-testid="top-fixed-message" className="message-container">
      {showTooltip ? (<HtmlTooltip
        title={ReactHtmlParser(messageContent)}
        leaveDelay={1000}
        maxWidth={400}
      >
        {ReactHtmlParser(messageContent)}
      </HtmlTooltip>) : (ReactHtmlParser(messageContent))}
    </div>) : null;
};

export default TopFixedMessage;
