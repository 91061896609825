import React from 'react';
import { LinearProgress } from "@material-ui/core";

export function Budget(props) {
    const { budget, budgets, practice, isDashboard } = props;

    const getBudgetName = (budget) => {
        let type = capitalizeFirstLetter(budget.type);
        return type;
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    const redirect = () => {
        if (isDashboard)
            sessionStorage.setItem("companyId", practice.companyId);
        props.history.push(`/orders/budgets?type=${budget.type == "WEEKLY" ? 0 : budget.type == "QUARTERLY" ? 2 : 1}`)
    }

    return <> <div className={`prg-bar w-100 cursor-pointer  ${isDashboard ? "mb-10 practice-budget-parent" : ""} ${budgets.length === 1 ? "align-center" : ""}`}>
        <LinearProgress variant="determinate"
            value={budget.percentageValue} className="mb-1 custom-progress-bar main-prog-line " />
        <div className={`new-budget hover-detail cursor-pointer  ${isDashboard ? "practice-budget" : ""}`} onClick={redirect}>
            <label>{getBudgetName(budget)}</label>
            <div>
                <p>Remaining <b>: {budget.remaining}</b></p>
                <p style={{ textAlign: "right" }}>% of Budget <b>: {budget.percentage}</b></p>
            </div>
        </div>
    </div>
    </>
}

