/**
 * App Constants
*/

export default {
   AppLogo: require('../assets/images/pricescout_images/vpc-final-logo.png'),         // App logo
   rtlLayout: false,                                             // RTL Layout
   adminLayout: false,                                            // Admin Layout
   navCollapsed: false,                                          // Sidebar Nav Layout
   algoliaConfig: {                                              // Algolia configuration
      appId: 'latency',
      apiKey: '3d9875e51fbd20c7754e65422f7ce5e1',
      indexName: 'bestbuy'
   },
   // Default locale
   locale: {
      locale: 'en',
      name: 'English',
      icon: 'en',
   },
   // Footer about description
   // AboutUs: 'Here you can use rows and columns here to organize your footer content.Lorem ipsum dolor sit amet,consectetur adipisicing elit. Here you can use rows and columns here to organize your footer content. Lorem ipsum dolor sit amet,        consectetur adipisicing elit.',
   // Copyright text
   CopyrightText: '© Default store 2019 - Powered by VetPriceScout'
}