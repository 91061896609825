/**
 * Sidebar menu component
 */
import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import { Scrollbars } from 'react-custom-scrollbars';
import { List } from '@material-ui/core';
import { connect } from 'react-redux';
import { local } from "../../../constant";
import { getAccessToken } from "../../../Authorization";
//data
// import { CustomerNavLinks, CustomerFinancialsNavLinks } from '../../../assets/data/NavLinks.js';

//component
import MenuListItem from './MenuListItem';

class SidebarMenu extends React.Component {

   state = {
      open: false,
      links: []
   };

   /**
    * function for toggle sidebar drawer
    */
   toggleDrawer() {
      this.setState({
         open: !this.state.open
      });
   };

   /**
    * function for close sidebar drawer
    */
   closeDrawer() {
      this.setState({
         open: false
      });
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.links != this.props.links) {
         this.setState({ links: nextProps.links });
      }
   }


   render() {
      const { links } = this.state;
      const role = sessionStorage.getItem("role");
      return (

         <div className="py-10 text-right iron-sidebar-nav-wrap">
            {
               (role === "CUSTOMER") ?
                  <div >
                     <IconButton onClick={this.toggleDrawer.bind(this)} className="nav-menu-icon">
                        <i className="material-icons">menu</i>
                     </IconButton>
                     <SwipeableDrawer
                        open={this.state.open}
                        onClose={this.toggleDrawer.bind(this)}
                        onOpen={this.toggleDrawer.bind(this)}
                        className="sidebar-area"
                     >
                        <Scrollbars
                           autoHide
                           autoHideDuration={100}
                           style={{ width: 280, height: 'calc(100vh - 2rem)' }}
                        >
                           <div className="vertical-menu py-40">
                              <List component="nav" className="iron-sidebar-menu">
                                 {links.map((NavLink, index) => (
                                    <MenuListItem
                                       menu={NavLink}
                                       key={index}
                                       closeDrawer={() => this.closeDrawer()}
                                    />
                                 ))}
                              </List>
                           </div>
                        </Scrollbars>
                     </SwipeableDrawer>
                  </div>
                  : ""
            }
         </div>

      );
   }
}

const mapStateToProps = ({ ecommerce }) => {
   const { profile } = ecommerce;
   return { profile };
}

export default connect(mapStateToProps, {})(SidebarMenu);


