import * as React from "react";

function WhiteLabelsSvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ marginRight: "10px" }}
    >
      <path
        d="M21.9852 10.7531L21.2914 3.42656C21.2563 3.04687 20.9539 2.74687 20.5743 2.70937L13.2477 2.01562H13.2383C13.1633 2.01562 13.1047 2.03906 13.0602 2.08359L2.08441 13.0594C2.06268 13.0811 2.04545 13.1068 2.03368 13.1352C2.02192 13.1635 2.01587 13.1939 2.01587 13.2246C2.01587 13.2553 2.02192 13.2857 2.03368 13.3141C2.04545 13.3424 2.06268 13.3682 2.08441 13.3898L10.611 21.9164C10.6555 21.9609 10.7141 21.9844 10.7774 21.9844C10.8407 21.9844 10.8993 21.9609 10.9438 21.9164L21.9196 10.9406C21.9664 10.8914 21.9899 10.8234 21.9852 10.7531ZM10.775 19.5633L4.43754 13.2258L13.8055 3.85781L19.5946 4.40625L20.143 10.1953L10.775 19.5633ZM15.9383 6C14.8016 6 13.8758 6.92578 13.8758 8.0625C13.8758 9.19922 14.8016 10.125 15.9383 10.125C17.075 10.125 18.0008 9.19922 18.0008 8.0625C18.0008 6.92578 17.075 6 15.9383 6ZM15.9383 8.8125C15.5235 8.8125 15.1883 8.47734 15.1883 8.0625C15.1883 7.64766 15.5235 7.3125 15.9383 7.3125C16.3532 7.3125 16.6883 7.64766 16.6883 8.0625C16.6883 8.47734 16.3532 8.8125 15.9383 8.8125Z"
        fill={props.color}
        fill-opacity="0.7"
      />
    </svg>
  );
}

export default WhiteLabelsSvgComponent;
