/**
 * header search widget
 */
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Autosuggest from "react-autosuggest";
import { local } from "../../../constant";
import {
  addProductItem,
  showAlert,
  addToWishlist,
  getCompanies,
  makeCompanyActive,
  logError,
} from "../../../actions/action";
import { connect } from "react-redux";
import { getAccessToken, getAdditionalHeaders } from "../../../Authorization";
import "./searchBox.css";
import "./headers.css";

class CompanySearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      pageDetails: {},
      companies: [],
      value: "",
      selectedProduct: {},
      id: "",
    };
  }

  componentDidMount() {
    this.props.getCompanies();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.companies !== this.props.companies) {
      this.setState({ companies: nextProps.companies });
    }
  }
  /**
   * Method To Handle Search Form
   */
  handleSearchForm(isOpen) {
    this.setState({ suggestions: [], value: "" });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    const list = value && value.length > 0 ? this.getSuggestions(value) : [];
    this.setState({ suggestions: list ? list : [] });
  };

  getSuggestions(event) {
    fetch(local.baseUrl + `api/v1/company/name?name=${event.toLowerCase()}`, {
      method: "get",
      headers: new Headers({
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "bearer " + getAccessToken(),
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          this.props.logError(response);
          return;
        }
      })
      .then((data) => {
        this.setState({ suggestions: data });
        return data;
      });
  }

  renderSuggestion(suggestion) {
    return (
      <div className="searched-product" style={{ cursor: "pointer" }}>
        <div>
          <ul className="w-full d-flex justify-content-between product-list">
            <li className="column-val p-10">
              <a>{suggestion.name}</a>
            </li>
          </ul>
        </div>
      </div>
    );
    // return (
    //       <div className="searched-product">
    //             <div>
    //                   <ul className="w-full d-flex justify-content-between product-list">
    //                         <p style={{ paddingLeft: '5px', textAlign: "center", marginTop: '1%', cursor: 'pointer', fontWeight: 'bold' }}>  {suggestion.name}</p>
    //                   </ul>
    //             </div>
    //       </div >
    // );
  }
  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return children ? (
      <div {...containerProps} className="suggestionContainer">
        <div className="d-flex justify-content-between rcnt-title p-10">
          <label className="column-head">Practices</label>
        </div>

        <div className="my-title">{children}</div>
      </div>
    ) : (
      <div></div>
    );
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  getSuggestionValue = (suggestion) => {
    // this.props.makeCompanyActive(suggestion.id);
    sessionStorage.setItem("id", JSON.stringify(suggestion.customerId));
    sessionStorage.setItem("companyId", JSON.stringify(suggestion.id));
    this.setState({ value: suggestion });
    window.location.reload();
    return suggestion.name;
  };

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  render() {
    const { suggestions, value } = this.state;
    const inputProps = {
      placeholder: "Change Practice",
      value,
      onChange: this.onChange,
      // onBlur: this.onBlur
    };
    return (
      <div
        className="search-form justify-content-between"
        style={{ background: "transparent", margin: "10px 0" }}
      >
        <div className="company-input-wrap main-search">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            inputProps={inputProps}
            shouldRenderSuggestions={() => true}
            required
          />
        </div>
        <IconButton
          // onClick={() => this.handleSearchForm(false)}
          className="company search-btn"
        >
          <i className="material-icons">search</i>
        </IconButton>
      </div>
    );
  }
}

// export default SearchBox;

const mapStateToProps = ({ ecommerce }) => {
  const { cart, companies } = ecommerce;
  return { cart, companies };
};
export default connect(mapStateToProps, {
  addProductItem,
  addToWishlist,
  showAlert,
  getCompanies,
  makeCompanyActive,
  logError,
})(CompanySearchBox);
