import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import Badge from '@material-ui/core/Badge';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { getNotifications } from '../../../actions/action';


class Notification extends React.Component {

   constructor(props) {
      super(props);
      this.confirmationDialog = React.createRef();
      this.state = {
         anchorEl: null,
         cart: [],
         notificationCount: 0
      };
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.notificationCount !== this.props.notificationCount) {
         this.setState({ notificationCount: nextProps.notificationCount });
      }
   }

   render() {
      const { anchorEl } = this.state;
      const open = Boolean(anchorEl);
      const { notificationCount } = this.state;
      return (
         <div className="iron-cart-wrap" >
            <IconButton component={Link}
               to="/notifications"
               color="inherit"
               aria-owns={open ? 'simple-popper' : null}
               aria-haspopup="true"
               variant="contained"
               className="icon-btn mr-10"
               aria-label="Cart"
            >
               <Badge
                  badgeContent={notificationCount ? notificationCount : 0}
                  color="secondary"
                  className="badge-active"
               >
                  <i className="material-icons">chat_bubble</i>
               </Badge>
            </IconButton>
         </div>

      );
   }
}

const mapStateToProps = ({ ecommerce }) => {
   const { cart,
      notifications,
      popupNotifications,
      notificationCount } = ecommerce;
   return { cart, notifications, popupNotifications, notificationCount };
}

export default connect(mapStateToProps, {
   getNotifications
})(Notification);

