/**
 * AdminInfo header widget
 */
/* eslint-disable */
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { Menu, Badge, Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import "./userLogout.css";
import ErrorIcon from "@material-ui/icons/Error";
import { local } from "../../../constant";
import { getProfile, getActiveCompany, logout } from "../../../actions/action";
import { connect } from "react-redux";
import { HtmlTooltip } from "../../global/html-tool-tip";

class UserLogout extends React.Component {
  constructor(props) {
    super(props);
    this.setLogoutInterval = null;
  }
  state = {
    anchorEl: null,
    user: {
      // url: require('../../../assets/avatars/user-4.jpg'),
      // alt: 'user'
    },
    company: {},
    menus: [
      {
        id: 1,
        title: "Profile",
        icon: "power_settings_new",
        path: "/userProfile",
      },
      {
        id: 2,
        title: "Contact Us",
        icon: "power_settings_new",
        path: "/contact-us",
      },
      {
        id: 3,
        title: "Logout",
        icon: "power_settings_new",
        path: "/",
      },
    ],
  };

  componentDidMount() {
    this.props.getActiveCompany();
    this.setLogoutInterval = setInterval(() => {
      let user_logoutdate = new Date(
        JSON.parse(localStorage.getItem("USER_LOGOUT_DATE"))
      );
      let curr_date = new Date();
      // if (user_logoutdate && curr_date >= user_logoutdate) {
      //   this.autoLogout();
      // }
    }, 2000);
  }

  autoLogout = () => {
        sessionStorage.clear();
    this.props.logout().then((res) => {
      clearInterval(this.setLogoutInterval);
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace(
        process.env.PUBLIC_URL + this.props.location.pathname
      );
    });
  };

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  eraseCookie(name) {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  componentWillUnmount() {
    clearInterval(this.setLogoutInterval);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.profile !== this.props.profile) {
      this.setState({ user: nextProps.profile });
    }
  }

  //define function for open dropdown
  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  //define function for close dropdown
  handleClose = (e) => {
    if (e.target.name === "Logout") {
      this.props.logout().then((res) => {
        let isRememberMe = this.getCookie("isRememberMe");
        if (isRememberMe == false || isRememberMe == null) {
          this.eraseCookie("isRememberMe");
          this.eraseCookie("username");
          this.eraseCookie("password");
        }
        sessionStorage.clear();
        localStorage.clear();
        window.location.replace(
          process.env.PUBLIC_URL + this.props.location.pathname
        );
      });
    }
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { user } = this.state;
    const {
      invalidCredentials,
      myCovertrusProgram,
      tokenExpired,
      errorMessage,
    } = this.props;
    return (
      <div className="iron-logout-wrap userLogout mr-10">
        <Badge
          badgeContent={
            <HtmlTooltip
              maxWidth={450}
              title={
                myCovertrusProgram?.active &&
                  myCovertrusProgram?.value === "PENDING"
                  ? "Action required – update your Covetrus id now"
                  : invalidCredentials
                    ? "Invalid credentials. Please update."
                    : tokenExpired
                      ? errorMessage
                      : ""
              }
            >
              <span>
                {invalidCredentials ||
                  tokenExpired ||
                  (myCovertrusProgram?.active &&
                    myCovertrusProgram?.value === "PENDING") ? (
                  <ErrorIcon
                    style={{
                      color: "#db1414",
                      background: "white",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  ""
                )}
              </span>
            </HtmlTooltip>
          }
        >
          <Avatar
            aria-owns={open ? "menu-appbar" : null}
            aria-haspopup="true"
            onClick={this.handleMenu}
            className="icon-btn"
            alt={user ? user.firstName + " " + user.lastName : ""}
            src={
              user && user.profilePic ? local.imageUrl + user.profilePic : ""
            }
          ></Avatar>
        </Badge>
        <div className="menuitems">
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={this.handleClose}
            className="iron-dropdown"
          >
            {this.state.menus.map((menu, index) =>
              menu.id == 1 ? (
                <MenuItem
                  key={index}
                  onClick={this.handleClose}
                  to={menu.path}
                  component={Link}
                  name={menu.title}
                >
                  {menu.title}
                  {invalidCredentials || tokenExpired ? (
                    // <i
                    //   className="material-icons ml-20"
                    //   style={{ color: "var(--primary)" }}
                    // >
                    //   error_outline
                    // </i>
                    <ErrorIcon
                      style={{ color: "#db1414", marginLeft: "20px" }}
                    />
                  ) : (
                    ""
                  )}
                </MenuItem>
              ) : (
                <MenuItem
                  key={index}
                  onClick={this.handleClose}
                  to={menu.path}
                  component={Link}
                  name={menu.title}
                >
                  {menu.title}
                </MenuItem>
              )
            )}
          </Menu>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ecommerce }) => {
  const {
    profile,
    invalidCredentials,
    myCovertrusProgram,
    tokenExpired,
    errorMessage,
  } = ecommerce;
  return {
    profile,
    invalidCredentials,
    myCovertrusProgram,
    tokenExpired,
    errorMessage,
  };
};

export default connect(mapStateToProps, {
  getProfile,
  getActiveCompany,
  logout,
})(UserLogout);
