import * as React from "react";

function AdvertisingSvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ marginRight: "10px" }}
    >
      <path
        d="M22.3125 18.5625H1.6875C1.58437 18.5625 1.5 18.6469 1.5 18.75V20.0625C1.5 20.1656 1.58437 20.25 1.6875 20.25H22.3125C22.4156 20.25 22.5 20.1656 22.5 20.0625V18.75C22.5 18.6469 22.4156 18.5625 22.3125 18.5625ZM22.3125 3.75H1.6875C1.58437 3.75 1.5 3.83437 1.5 3.9375V5.25C1.5 5.35312 1.58437 5.4375 1.6875 5.4375H22.3125C22.4156 5.4375 22.5 5.35312 22.5 5.25V3.9375C22.5 3.83437 22.4156 3.75 22.3125 3.75ZM14.25 15.4688C14.4563 15.4688 14.625 15.3 14.625 15.0938V8.90625C14.625 8.7 14.4563 8.53125 14.25 8.53125H2.25C2.04375 8.53125 1.875 8.7 1.875 8.90625V15.0938C1.875 15.3 2.04375 15.4688 2.25 15.4688H14.25ZM3.5625 10.2188H12.9375V13.7812H3.5625V10.2188ZM16.5 15.1406C16.5 15.2437 16.5844 15.3281 16.6875 15.3281H21.9375C22.0406 15.3281 22.125 15.2437 22.125 15.1406V13.8281C22.125 13.725 22.0406 13.6406 21.9375 13.6406H16.6875C16.5844 13.6406 16.5 13.725 16.5 13.8281V15.1406ZM16.6875 10.3594H21.9375C22.0406 10.3594 22.125 10.275 22.125 10.1719V8.85938C22.125 8.75625 22.0406 8.67188 21.9375 8.67188H16.6875C16.5844 8.67188 16.5 8.75625 16.5 8.85938V10.1719C16.5 10.275 16.5844 10.3594 16.6875 10.3594Z"
        fill={props.color}
        fill-opacity="0.7"
      />
    </svg>
  );
}

export default AdvertisingSvgComponent;
