/**
 * Action Types
 */
import {
  COLLAPSED_SIDEBAR,
  RTL_LAYOUT,
  ADD_TO_CART,
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  SHOW_ALERT,
  HIDE_ALERT,
  REMOVE_FROM_CART,
  UPDATE_PRODUCT_QUANTITY,
  CHANGE_CURRENCY,
  SET_LANGUAGE,
  FINAL_PAYMENT,
  MOVE_WISHLISTITEM_TO_CART,
  DELETE_USER,
  ADD_NEW_USER,
  DISPLAY_CART_ITEMS,
  DISPLAY_MERCHANT_CART_ITEMS,
  DISPLAY_WISHLIST_ITEMS,
  SHOW_VENDOR_LIST,
  SET_ROLE,
  DEFAULT_MERCHANT_DETAILS,
  SAVE_MERCHANT_DETAILS,
  UPDATE_MERCHANT_DETAILS,
  NEW_USER_SIGNUP,
  UPDATE_CART,
  ORDER_HISTORY,
  PRODUCT_ORDER_HISTORY,
  MANUFACURE_ORDER_HISTORY,
  PROFILE,
  COMPANIES,
  COMPANY_WISH_LIST,
  ADD_FAVORITE_ITEM,
  DELETE_FAVORITE_ITEM,
  UPDATE_FAVORITE_ITEM,
  ACTIVE_COMPANY,
  COMPANY_UPDATE_FLAG,
  NOTIFICATIONS,
  POPUP_NOTIFICATION,
  NOTIFICATION_COUNT,
  DISPLAY_MY_PRODUCTS_ITEMS,
  MANUFACTURER_PURCHASE_SUMMARY_REPORT,
  MERCHANT_PURCHASE_SUMMARY_REPORT,
  PRODUCT_PURCHASE_SUMMARY_REPORT,
  CATEGORY_PURCHASE_SUMMARY_REPORT,
  MANUFACTURER_FROM_REBATE,
  SHOW_GROUP_LIST,
  GROUP_AREA_MANAGERS,
  SHOW_ERROR,
  HIDE_ERROR,
  GET_MYCOVETRUS_DATA,
  LOGOUT,
  CARTITEMS,
  LOG_ERROR,
  LOG_ERROR_SUCCESS,
  SHOW_ADD_TO_CART_POPUP,
  SET_APP_LOGO,
  BUCKS,
  SHOW_VENDOR_CONNECT_POPUP,
  HIDE_VENDOR_CONNECT_POPUP,
  SET_INVALID_CREDENTIALS,
  SET_EXPIRED_TOKEN,
  BUDGETS,
  SHOW_SAVINGS_POPUP,
  HIDE_SAVINGS_POPUP,
  SHOW_SAMPLES_POPUP,
  HIDE_SAMPLES_POPUP,
} from "./types";
import { local } from "../constant";

// import { getUserAccessToken } from "../Authorization";
import { getAccessToken, getAdditionalHeaders } from "../Authorization";
import { getCustomerService } from "../services/customers";
import {
  addFavoriteItemService,
  deleteFavoriteItemService,
  getWishListService,
  unCheckFavoriteItemService,
  updateFavoriteItemService,
} from "../services";

//add product item
export const addProductItem = (data, alertMessage) => (dispatch) => {
  dispatch({
    type: ADD_TO_CART,
    payload: data,
  });
};
export const setRole = (data) => (dispatch) => {
  dispatch({
    type: SET_ROLE,
    payload: data,
  });
};

export const updateCart = (data, alertMessage) => (dispatch) => {
  dispatch({
    type: UPDATE_CART,
    payload: data,
  });
};

//show alert box
export const showAlert = (message, type) => ({
  type: SHOW_ALERT,
  payload: { message, type },
});

//hide alert box
export const hideAlert = () => ({
  type: HIDE_ALERT,
});

//show error
export const showError = (type) => ({
  type: SHOW_ERROR,
  payload: { type },
});
//hide error
export const hideError = () => ({
  type: HIDE_ERROR,
});

//log error
export const logError = (type) => ({
  type: LOG_ERROR,
  payload: { type },
});
//log error
export const showAddToCart = (flag, productsCopy) => ({
  type: SHOW_ADD_TO_CART_POPUP,
  payload: { flag, productsCopy },
});
//log error completed
export const logErrorSuccess = (type) => ({
  type: LOG_ERROR_SUCCESS,
});
//log error completed
export const setAppLogo = (url) => ({
  type: SET_APP_LOGO,
  payload: url,
});

export const showVendorConnectPopup = (vendor) => ({
  type: SHOW_VENDOR_CONNECT_POPUP,
  payload: vendor,
});

export const showSavingsPopup = (productId, product) => ({
  type: SHOW_SAVINGS_POPUP,
  payload: { productId, product },
});
export const setInvalidCredentials = (flag) => ({
  type: SET_INVALID_CREDENTIALS,
  payload: flag,
});

export const hideVendorConnectPopup = () => ({
  type: HIDE_VENDOR_CONNECT_POPUP,
});
export const hideSavingsPopup = () => ({
  type: HIDE_SAVINGS_POPUP,
});

export const showSamplesPopup = (data) => ({
  type: SHOW_SAMPLES_POPUP,
  payload: data,
});

export const hideSamplesPopup = () => ({
  type: HIDE_SAMPLES_POPUP,
});
// Get Profile
export const getProfile = () => (dispatch) => {
  if (getAccessToken()) {
    getCustomerService()
      .then((res) => {
        const { data } = res;
        if (data) {
          dispatch({
            type: PROFILE,
            payload: data,
          });
          if (data && data.groups && data.groups.length > 0) {
            let list = [];
            data.groups.map((group, i) => {
              list.push(group.groupType);
            });
            sessionStorage.setItem("roles", list);
          }
        }
      })
      .catch((error) => {
        logError(error.response);
      });
  }
};

export const getCompanyWishLists = () => (dispatch) => {
  if (getAccessToken()) {
    getWishListService().then((res) => {
      const { data } = res;
      if (data) {
        dispatch({
          type: COMPANY_WISH_LIST,
          payload: data.names,
        });
      }
    });
  }
};

export const addFavoriteItem =
  (postObj, dispatchable = true) =>
    (dispatch) => {
      return addFavoriteItemService(postObj)
        .then((res) => {
          const { data } = res;
          if (data?.code === 200 && dispatchable) {
            dispatch({
              type: ADD_FAVORITE_ITEM,
              payload: postObj.name,
            });
          }
          return data;
        })
        .catch((error) => {
          return error.responseata;
        });
    };

export const deleteFavoriteItem = (postObj) => (dispatch) => {
  return deleteFavoriteItemService(postObj)
    .then((res) => {
      const { data } = res;
      if (data) {
        dispatch({
          type: DELETE_FAVORITE_ITEM,
          payload: postObj.name,
        });
      }
      return res;
    })
    .catch((err) => err.response);
};

export const updateFavoriteItem = (postObj) => (dispatch) => {
  return updateFavoriteItemService(postObj)
    .then((res) => {
      const { data } = res;
      if (data) {
        dispatch({
          type: UPDATE_FAVORITE_ITEM,
          payload: postObj,
        });
      }
      return res;
    })
    .catch((err) => err.response);
};

export const unCheckWishListName = (postObj) => (dispatch) => {
  return unCheckFavoriteItemService(postObj)
    .then((res) => {
      const { data } = res;
      return data;
    })
    .catch((err) => err.response);
};

export const makeCompanyActive = (id) => (dispatch) => {
  var sessionData = getAccessToken();
  fetch(local.baseUrl + `api/v3/company/update-to-active?id=${id}`, {
    method: "post",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + sessionData,
      ...getAdditionalHeaders(),
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      if (data.status === 0) {
        dispatch({
          type: SHOW_ALERT,
          payload: {
            message: "Please add vendor before making company active",
            type: "error",
          },
        });
      } else {
        sessionStorage.setItem("companyId", data.activeCompany.id);
        dispatch({
          type: ACTIVE_COMPANY,
          payload: data.activeCompany,
        });
        dispatch({
          type: COMPANIES,
          payload: data.companyList,
        });
        window.location.reload();
      }
    });
};

// Get Active Company
export const getActiveCompany = () => (dispatch) => {
  var sessionData = getAccessToken();
  var companyId = sessionStorage.getItem("companyId");
  if (companyId && companyId != "null") {
    fetch(local.baseUrl + `api/v1/company/find-by-id?id=${companyId}`, {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        if (data) {
          dispatch({
            type: ACTIVE_COMPANY,
            payload: data,
          });
        }
      });
  }
};

export const getBucks = () => (dispatch) => {
  var sessionData = getAccessToken();
  if (sessionData) {
    fetch(local.baseUrl + `api/v3/group/balance`, {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        if (data) {
          dispatch({
            type: BUCKS,
            payload: data,
          });
        }
      });
  }
};

// Get Companies
export const getCompanies = () => (dispatch) => {
  var sessionData = getAccessToken();
  fetch(local.baseUrl + `api/v3/company`, {
    method: "get",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + sessionData,
      ...getAdditionalHeaders(),
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      dispatch({
        type: COMPANIES,
        payload: data,
      });
    });
};

export const getBugetList = () => (dispatch) => {
  var sessionData = getAccessToken();
  fetch(local.baseUrl + `api/v3/budget/dashboard`, {
    method: "get",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + sessionData,
      ...getAdditionalHeaders(),
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      dispatch({
        type: BUDGETS,
        payload: data,
      });
    });
};
// save company details
export const saveCompany = (postObj) => (dispatch) => {
  var sessionData = getAccessToken();
  fetch(local.baseUrl + `api/v1/company/save`, {
    method: "post",
    body: JSON.stringify(postObj),
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + sessionData,
      ...getAdditionalHeaders(),
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        getProfile();
        getCompanies();
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      if (data && data.emailPresent) {
        dispatch({
          type: SHOW_ALERT,
          payload: { message: data.message, type: "error" },
        });
      } else {
        if (data && data.active) {
          dispatch({
            type: ACTIVE_COMPANY,
            payload: data,
          });
        }
        dispatch({
          type: COMPANY_UPDATE_FLAG,
        });
      }
    });
};

// show vendor list
export const showVendorList = (tempRole) => (dispatch) => {
  var role = tempRole ? tempRole : sessionStorage.getItem("role");
  if (getAccessToken()) {
    fetch(local.baseUrl + `api/v3/stores/active/${role}`, {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + getAccessToken(),
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        dispatch({
          type: SHOW_VENDOR_LIST,
          payload: data,
        });
        if (data && data.length > 0) {
          let flag = false;
          let vendorObj = {};
          data.map((vendor) => {
            if (vendor?.tokenExpired) {
              vendorObj = {
                tokenExpired: true,
                errorMessage: vendor.errorMessage,
              };
            }
            if (vendor.available && vendor.invalidCredentials) {
              flag = true;
            }
          });
          dispatch({
            type: SET_EXPIRED_TOKEN,
            payload: vendorObj,
          });
          dispatch({
            type: SET_INVALID_CREDENTIALS,
            payload: flag,
          });
        }
      })
      .catch((error) => error);
  }
};
export const getMyCovertrusProgram = () => (dispatch) => {
  fetch(
    local.baseUrl + `api/v3/program/component?componentName=mycovetrus_program`,
    {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + getAccessToken(),
        ...getAdditionalHeaders(),
      }),
    }
  )
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      if (data) {
        dispatch({
          type: GET_MYCOVETRUS_DATA,
          payload: data,
        });
      }
    });
};
export const showVendors = (tempRole) => (dispatch) => {
  var role = tempRole ? tempRole : sessionStorage.getItem("role");
  if (getAccessToken()) {
    fetch(local.baseUrl + `api/v4/stores/active/${role}`, {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + getAccessToken(),
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        dispatch({
          type: SHOW_VENDOR_LIST,
          payload: data,
        });
        if (data && data.length > 0) {
          let flag = false;
          let tokenExpired = false;
          let vendorObj = {};
          data.map((vendor) => {
            if (vendor?.tokenExpired) {
              vendorObj = {
                tokenExpired: true,
                errorMessage: vendor.errorMessage,
              };
            }
            if (vendor.available && vendor.invalidCredentials) {
              flag = true;
            }
          });
          dispatch({
            type: SET_INVALID_CREDENTIALS,
            payload: flag,
          });
          dispatch({
            type: SET_EXPIRED_TOKEN,
            payload: vendorObj,
          });
        }
      })
      .catch((error) => error);
  }
};

export const showGroupList = () => (dispatch) => {
  var sessionData = getAccessToken();
  let url = "";
  if (window.location.pathname && window.location.pathname.includes("admin")) {
    url = "default";
  } else {
    url = window.location.host;
  }
  fetch(local.baseUrl + `api/v3/group/active?whiteLabel=${url}`, {
    method: "get",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + sessionData,
      ...getAdditionalHeaders(),
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      dispatch({
        type: SHOW_GROUP_LIST,
        payload: data,
      });
      // return data
    })
    .catch((error) => error);
};
export const getGroupListData = (companyId) => (dispatch) => {
  fetch(local.baseUrl + `api/v4/group/active?companyId=${companyId}`, {
    method: "get",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + getAccessToken(),
      ...getAdditionalHeaders(),
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      dispatch({
        type: SHOW_GROUP_LIST,
        payload: data,
      });
      // return data
    })
    .catch((error) => error);
};

//show wishlist items
export const showWishList = (
  start,
  count,
  name,
  sortby,
  manufacturerName,
  wishlistName,
  relationType
) => {
  return (dispatch) => {
    var sessionData = getAccessToken();
    var param = "";
    if (start || start === 0) {
      param = "?start=" + start;
    }
    if (count) {
      param += "&count=" + count;
    }
    if (name && name.length > 0) {
      param += "&name=" + name;
    }
    if (sortby) {
      param += "&sortby=" + sortby;
    }
    if (manufacturerName && manufacturerName.length > 0) {
      param += "&manufacturerName=" + manufacturerName;
    }
    if (wishlistName) {
      param += "&wishlistName=" + wishlistName;
    }
    const code = relationType || "WISHLIST_ITEM";
    const companyId = sessionStorage.getItem("companyId");
    return fetch(
      local.baseUrl + `api/v2/products/group/${code}/${companyId}${param}`,
      {
        method: "get",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + sessionData,
          ...getAdditionalHeaders(),
        }),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          dispatch({
            type: SHOW_ERROR,
            payload: response.status,
          });
          logError(response);
          return;
        }
      })
      .then((data) => {
        dispatch({
          type: DISPLAY_WISHLIST_ITEMS,
          payload: data.products,
        });
        return data;
      })
      .catch((error) => error);
  };
};

export const promotion = (
  start,
  count,
  name,
  sortby,
  merchantIds,
  price,
  manufacturerIds,
  filterPreferred,
  filterFevorites,
  filterPreviouslyPurchased,
  availableTypes
) => {
  return (dispatch) => {
    var sessionData = getAccessToken();
    var param = "";
    if (start || start === 0) {
      param = "?start=" + start;
    }
    if (count) {
      param += "&count=" + count;
    }
    if (name && name.length > 0) {
      param += "&name=" + name;
    }
    if (sortby) {
      param += "&sort=" + sortby;
    }

    if (manufacturerIds && manufacturerIds.length > 0) {
      param += "&manufacturerIds=" + manufacturerIds;
    }

    if (availableTypes && availableTypes.length > 0) {
      param += "&availableTypes=" + availableTypes;
    }

    if (merchantIds) {
      param += "&merchantIds=" + merchantIds;
    }

    param += "&price=" + price;
    param += "&filterPreferred=" + filterPreferred;
    param += "&filterFevorites=" + filterFevorites;
    param += "&filterPreviouslyPurchased=" + filterPreviouslyPurchased;
    return fetch(local.baseUrl + `api/v3/products/promotions${param}`, {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          dispatch({
            type: SHOW_ERROR,
            payload: response.status,
          });
          logError(response);
          return;
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => error);
  };
};

export const promotionManufacturerList = (name, sortby, merchantIds, price) => {
  return (dispatch) => {
    var sessionData = getAccessToken();
    var param = "";
    param = "?merchantIds=" + merchantIds;
    if (name && name.length > 0) {
      param += "&name=" + name;
    }
    if (sortby) {
      param += "&sort=" + sortby;
    }

    param += "&price=" + price;
    return fetch(local.baseUrl + `api/v3/manufacturers/promotion${param}`, {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => error);
  };
};

//show my product items
export const showMyProductsList = (
  start,
  count,
  name,
  sortby,
  manufacturerName
) => {
  return (dispatch) => {
    var sessionData = getAccessToken();
    var param = "";
    if (start || start === 0) {
      param = "?start=" + start;
    }
    if (count) {
      param += "&count=" + count;
    }
    if (name && name.length > 0) {
      param += "&name=" + name;
    }
    if (sortby) {
      param += "&sortby=" + sortby;
    }
    if (manufacturerName && manufacturerName.length > 0) {
      param += "&manufacturerName=" + manufacturerName;
    }
    return fetch(local.baseUrl + `api/v3/products/group/MY_PRODUCT${param}`, {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          dispatch({
            type: SHOW_ERROR,
            payload: response.status,
          });
          logError(response);
          return;
        }
      })

      .then((data) => {
        dispatch({
          type: DISPLAY_MY_PRODUCTS_ITEMS,
          payload: data.products,
        });
        return data;
      })
      .catch((error) => error);
  };
};

// add product to wishlist
export const addToWishlist = (product) => (dispatch) => {
  var productId = product.id;
  return fetch(
    local.baseUrl +
    `api/v3/addWishListItem?merchantCode=${local.merchantCode}&productId=${productId}`,
    {
      method: "post",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + getAccessToken(),
        ...getAdditionalHeaders(),
      }),
    }
  ).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      logError(response);
      return;
    }
  });
};

// remove product from wishlist
export const removeFromWishlist = (product, alertMessage) => (dispatch) => {
  var productId = product.id;
  return fetch(
    local.baseUrl +
    `api/v3/removeWishListItem?merchantCode=${local.merchantCode}&productId=${productId}`,
    {
      method: "post",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + getAccessToken(),
        ...getAdditionalHeaders(),
      }),
    }
  )
    .then((response) => {
      if (response.status === 200) {
        return true;
      } else {
        logError(response);
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
};

//move all wishlist item to cart
export const moveWishlistItemToCart = () => ({
  type: MOVE_WISHLISTITEM_TO_CART,
});

//delete wishlist item
export const deleteItemFromWishlist = (data) => ({
  type: REMOVE_FROM_WISHLIST,
  payload: data,
});

//Remove product item
// export const removeProductItem = (data) => ({

//    type: REMOVE_FROM_CART,
//    payload: data
// })

export const getNotificationCount = () => (dispatch) => {
  fetch(local.baseUrl + `api/v3/notification/new-notification-count`, {
    method: "get",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + getAccessToken(),
      ...getAdditionalHeaders(),
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      if (data && data.unreadCount != null) {
        dispatch({
          type: NOTIFICATION_COUNT,
          payload: data.unreadCount,
        });
      }
      return data;
    });
};

export const getNotifications = () => (dispatch) => {
  if (getAccessToken()) {
    fetch(local.baseUrl + `api/v3/notification/announcements`, {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + getAccessToken(),
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        if (data) {
          dispatch({
            type: NOTIFICATIONS,
            payload: data,
          });
          return data;
        }
      });
  }
};

export const getPopupNotification = () => (dispatch) => {
  fetch(local.baseUrl + `api/v3/notification/popup`, {
    method: "get",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + getAccessToken(),
      ...getAdditionalHeaders(),
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      if (data) {
        dispatch({
          type: POPUP_NOTIFICATION,
          payload: data,
        });
      }
      return data;
    });
};

export const saveReadNotifications = (notifications) => {
  return (dispatch) => {
    return fetch(
      local.baseUrl + `api/v1/notification/save-customer-announcement`,
      {
        method: "post",
        body: JSON.stringify(notifications),
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + getAccessToken(),
          ...getAdditionalHeaders(),
        }),
      }
    ).then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        logError(response);
        return response;
      }
    });
  };
};

export const popUpShown = (notificationId) => (dispatch) => {
  let postObj = {
    notificationId: notificationId,
    popUp: true,
  };
  fetch(local.baseUrl + `api/v3/notification/save-customer-popup`, {
    method: "post",
    body: JSON.stringify(postObj),
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + getAccessToken(),
      ...getAdditionalHeaders(),
    }),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      logError(response);
      return;
    }
  });
};

export const removeProductItem = (data) => (dispatch) => {
  fetch(
    local.baseUrl +
    `api/v1/removeShoppingCartItem?lineItemId=${data.removeItem.id}`,
    {
      method: "post",
      // body: JSON.stringify(postObj),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + getAccessToken(),
        ...getAdditionalHeaders(),
      }),
    }
  )
    .then((response) => {
      if (response.status === 200) {
        getShoppingCartCount();
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => error);
};

export const removeProductItemWithProductId = (data) => (dispatch) => {
  fetch(
    local.baseUrl +
    `api/v3/removeShoppingCartItemWithProductId?productId=${data.productId}`,
    {
      method: "post",
      // body: JSON.stringify(postObj),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + getAccessToken(),
        ...getAdditionalHeaders(),
      }),
    }
  )
    .then((response) => {
      if (response.status === 200) {
        dispatch(getShoppingCartCount());
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => error);
};

export const getShoppingCartCount = () => (dispatch) => {
  var sessionData = getAccessToken();
  fetch(local.baseUrl + `api/v3/getShoppingCartCount`, {
    method: "get",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + sessionData,
      ...getAdditionalHeaders(),
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      if (data) {
        dispatch(displayCartItems());
        dispatch({
          type: CARTITEMS,
          payload: data.cart,
        });
      }
    });
};

export const displayCartItems = () => (dispatch) => {
  fetch(local.baseUrl + `api/v4/displayMiniCartByCode`, {
    method: "get",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + getAccessToken(),
      ...getAdditionalHeaders(),
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      dispatch({
        type: DISPLAY_CART_ITEMS,
        payload: data ? data : [],
      });
      return data;
    })
    .catch((error) => error);
};

export const displayCartItemsByMerchants = () => (dispatch) => {
  fetch(local.baseUrl + `api/v4/displayMiniCart`, {
    method: "get",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + getAccessToken(),
      ...getAdditionalHeaders(),
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        dispatch({
          type: SHOW_ERROR,
          payload: response.status,
        });
        logError(response);
        return;
      }
    })
    .then((data) => {
      dispatch({
        type: DISPLAY_MERCHANT_CART_ITEMS,
        payload: data ? data : [],
      });
      return data;
    })
    .catch((error) => error);
};

export const orderItem = (
  pageStart,
  PageSize,
  startDate,
  endDate,
  isBackorder,
  name,
  sortby,
  vendor,
  shippingStatus,
  manufactureName
) => {
  return (dispatch) => {
    var sessionData = getAccessToken();
    var startDateParameter = "";
    if (startDate && startDate.length > 0) {
      startDateParameter = "&startDate=" + startDate;
    }

    var endDateParameter = "";
    if (endDate && endDate.length > 0) {
      endDateParameter = "&endDate=" + endDate;
    }

    let backorderParameter = "";
    if (isBackorder) {
      backorderParameter = "&backorder=" + isBackorder;
    }

    // Search query from input box is store in param
    let param = "";
    if (name && name.length > 0) {
      param += "&query=" + name;
    }

    // sortby
    if (sortby) {
      param += "&sortby=" + sortby;
    }

    if (vendor) {
      param += "&vendor=" + vendor;
    }
    if (shippingStatus) {
      param += "&shippingStatus=" + shippingStatus;
    }
    if (manufactureName) {
      param += "&manufactureName=" + manufactureName;
    }

    return fetch(
      local.baseUrl +
      `api/v3/displayAllOrderDataByCompany?start=${pageStart}&count=${PageSize}${startDateParameter}${endDateParameter}${backorderParameter}${param}`,
      {
        method: "get",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + sessionData,
          ...getAdditionalHeaders(),
        }),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          dispatch({
            type: SHOW_ERROR,
            payload: response.status,
          });
          logError(response);
          return;
        }
      })
      .then((data) => {
        dispatch({
          type: ORDER_HISTORY,
          payload: data.orderDatas,
        });
        return data;
      })
      .catch((error) => error);
  };
};

export const rebates = (selectedManufacturers) => {
  return (dispatch) => {
    var sessionData = getAccessToken();

    return fetch(
      local.baseUrl +
      `api/v3/rebate?manufacturerMappingIds=${selectedManufacturers}`,
      {
        method: "get",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + sessionData,
          ...getAdditionalHeaders(),
        }),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => error);
  };
};

export const orderItemByProduct = (
  pageStart,
  PageSize,
  startDate,
  endDate,
  name,
  sortby,
  vendor
) => {
  return (dispatch) => {
    var sessionData = getAccessToken();
    var startDateParameter = "";
    if (startDate && startDate.length > 0) {
      startDateParameter = "&startDate=" + startDate;
    }
    var endDateParameter = "";
    if (endDate && endDate.length > 0) {
      endDateParameter = "&endDate=" + endDate;
    }
    // Search query from input box is store in param
    let param = "";
    if (name && name.length > 0) {
      param += "&query=" + name;
    }
    // sortby in the param
    if (sortby) {
      param += "&sortby=" + sortby;
    }
    if (vendor) {
      param += "&vendor=" + vendor;
    }
    return fetch(
      local.baseUrl +
      `api/v3/displayallproductorderdatabycompany?start=${pageStart}&count=${PageSize}${startDateParameter}${endDateParameter}${param}`,
      {
        method: "get",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + sessionData,
          ...getAdditionalHeaders(),
        }),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          dispatch({
            type: SHOW_ERROR,
            payload: response.status,
          });
          logError(response);
          return;
        }
      })
      .then((data) => {
        dispatch({
          type: PRODUCT_ORDER_HISTORY,
          payload: data.productOrderData,
        });
        return data;
      })
      .catch((error) => error);
  };
};

export const orderMaufactureItem = (
  pageStart,
  PageSize,
  startDate,
  endDate,
  name,
  sortby,
  vendor
) => {
  return (dispatch) => {
    var sessionData = getAccessToken();
    var startDateParameter = "";
    if (startDate && startDate.length > 0) {
      startDateParameter = "&startDate=" + startDate;
    }
    var endDateParameter = "";
    if (endDate && endDate.length > 0) {
      endDateParameter = "&endDate=" + endDate;
    }
    let param = "";
    if (name && name.length > 0) {
      param += "&query=" + name;
    }

    // let param = "";
    if (sortby) {
      param += "&sortby=" + sortby;
    }
    if (vendor) {
      param += "&vendor=" + vendor;
    }

    return fetch(
      local.baseUrl +
      `api/v3/displayallmanufactureorderdatabycompany?start=${pageStart}&count=${PageSize}${startDateParameter}${endDateParameter}${param}`,
      {
        method: "get",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + sessionData,
          ...getAdditionalHeaders(),
        }),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          dispatch({
            type: SHOW_ERROR,
            payload: response.status,
          });
          logError(response);
          return;
        }
      })
      .then((data) => {
        dispatch({
          type: MANUFACURE_ORDER_HISTORY,
          payload: data.manufactureOrderData,
        });
        return data;
      })
      .catch((error) => error);
  };
};

// default merchant details
export const defaultMerchantDetails = (merchantUserId) => (dispatch) => {
  var sessionData = getAccessToken();
  fetch(local.baseUrl + `api/v1/stores/merchantuser/${merchantUserId}`, {
    method: "get",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + sessionData,
      ...getAdditionalHeaders(),
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        logError(response);
        return;
      }
    })
    .then((data) => {
      dispatch({
        type: DEFAULT_MERCHANT_DETAILS,
        payload: data,
      });
      // return data
    })
    .catch((error) => error);
};

export const placeCartOrder = (data) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + `api/v3/placeorder`, {
      method: "post",
      body: JSON.stringify(data),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    }).then((response) => {
      if (response.status === 200) {
        getShoppingCartCount();
        return response;
      } else {
        logError(response);
        return response;
      }
    });
  };
};

export const placeCartOrderWithVendor = (data) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + `api/v3/placeorderwithvendor`, {
      method: "post",
      body: JSON.stringify(data),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    }).then((response) => {
      if (response.status === 200) {
        getShoppingCartCount();
        return response;
      } else {
        logError(response);
        return response;
      }
    });
  };
};

// save Group details
export const saveGroupDetails = (postObj) => {
  var sessionData = getAccessToken();

  return (dispatch) => {
    return fetch(local.baseUrl + "api/v1/groupuser/save", {
      body: JSON.stringify(postObj),
      method: "post",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    }).then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        logError(response);
        return response;
      }
    });
  };
};
// save Group Admin List
export const getGroupAdminList = (postObj) => {
  var sessionData = getAccessToken();

  return (dispatch) => {
    return fetch(local.baseUrl + "api/v1/group/admin", {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    }).then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        logError(response);
        return response;
      }
    });
  };
};
// save merchant details
export const saveMerchantDetails = (postObj) => {
  var sessionData = getAccessToken();

  return (dispatch) => {
    return fetch(local.baseUrl + "api/v3/store/save/merchantuser", {
      body: JSON.stringify(postObj),
      method: "post",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    }).then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        logError(response);
        return response;
      }
    });
  };
};

// update merchant details
export const updateMerchantDetails = (postObj) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + "api/v3/store/update/merchantuser", {
      body: JSON.stringify(postObj),
      method: "post",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    }).then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        logError(response);
        return response;
      }
    });
  };
};

export const logout = () => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + `api/v1/logout`, {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    }).then((response) => {
      if (response.status === 200) {
        dispatch(
          {
            type: PROFILE,
            payload: {},
          },
          {
            type: LOGOUT,
          }
        );
        return response;
      } else {
        logError(response);
        return response;
      }
    });
  };
};
export const getPreference = (type, page) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + `api/v3/preference/${type}/${page}`, {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    }).then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        logError(response);
        return response;
      }
    });
  };
};
export const savePreference = (postObj) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + `api/v3/preference/saveOrUpdate`, {
      body: JSON.stringify(postObj),
      method: "post",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    }).then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        logError(response);
        return response;
      }
    });
  };
};

export const getGroupAreaManagers = () => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + `api/v1/group/areamanagers`, {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        dispatch({
          type: GROUP_AREA_MANAGERS,
          payload: data,
        });
        // return data
      })
      .catch((error) => error);
  };
};

export const getManufacturerPurchaseSummary = (postObj) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + `api/v3/manufacturerPurchaseSummary`, {
      body: JSON.stringify(postObj),
      method: "post",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        dispatch({
          type: MANUFACTURER_PURCHASE_SUMMARY_REPORT,
          payload: data,
        });
        // return data
      })
      .catch((error) => error);
  };
};

export const getMerchantPurchaseSummary = (postObj) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + `api/v3/merchantPurchaseSummary`, {
      body: JSON.stringify(postObj),
      method: "post",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        dispatch({
          type: MERCHANT_PURCHASE_SUMMARY_REPORT,
          payload: data,
        });
        // return data
      })
      .catch((error) => error);
  };
};

export const getProductPurchaseSummary = (postObj) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + `api/v3/productPurchaseSummary`, {
      body: JSON.stringify(postObj),
      method: "post",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        dispatch({
          type: PRODUCT_PURCHASE_SUMMARY_REPORT,
          payload: data,
        });
        // return data
      })
      .catch((error) => error);
  };
};

export const getCategoryPurchaseSummary = (postObj) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + `api/v3/categoryPurchaseSummary`, {
      body: JSON.stringify(postObj),
      method: "post",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        dispatch({
          type: CATEGORY_PURCHASE_SUMMARY_REPORT,
          payload: data,
        });
        // return data
      })
      .catch((error) => error);
  };
};

export const getCategoryPurchaseSummaryBudget = (postObj) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + `api/v3/budgetCategoryPurchaseSummary`, {
      body: JSON.stringify(postObj),
      method: "post",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        dispatch({
          type: CATEGORY_PURCHASE_SUMMARY_REPORT,
          payload: data,
        });
        // return data
      })
      .catch((error) => error);
  };
};

export const getManufacturerFromRebate = (postObj) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + `api/v1/rebate/manufacurers`, {
      method: "get",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          logError(response);
          return;
        }
      })
      .then((data) => {
        dispatch({
          type: MANUFACTURER_FROM_REBATE,
          payload: data,
        });
        // return data
      })
      .catch((error) => error);
  };
};

// user signup
export const newUserSignup = (user) => (dispatch) => { };

// save to local storage
export const saveToLocalStorage =
  (localStorageField, field, value, redirect, params = {}) => (dispatch) => {
    let requestParams = { updated: 1, ...params };
    var str = "";
    for (var key in requestParams) {
      if (str != "") {
        str += "&";
      }
      str += key + "=" + encodeURIComponent(requestParams[key]);
    }
    if (redirect) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?${str}`;
      window.history.pushState({ path: newurl }, "", newurl);
    }
    let obj = JSON.parse(localStorage.getItem(localStorageField));
    if (obj) {
      obj[field] = value;
    } else {
      let newObj = {
        [field]: value,
      };
      obj = newObj;
    }
    localStorage.setItem(localStorageField, JSON.stringify(obj));
  };

//update product quantity
export const updateProductQuantity = (data) => ({
  type: UPDATE_PRODUCT_QUANTITY,
  payload: data,
});

// change currency
export const changeCurrency = (currency) => ({
  type: CHANGE_CURRENCY,
  payload: currency,
});

// change language
export const setLanguage = (locale) => ({
  type: SET_LANGUAGE,
  payload: locale,
});

//final payment
export const finalPayment = (history) => (dispatch) => {
  history.push("/final-receipt");
  dispatch({ type: FINAL_PAYMENT });
};

// Rtl Layout
export const rtlLayoutAction = (isRtlLayout) => ({
  type: RTL_LAYOUT,
  payload: isRtlLayout,
});

//Redux Action To Emit Collapse Sidebar
export const collapsedSidebarAction = (isCollapsed) => ({
  type: COLLAPSED_SIDEBAR,
  isCollapsed,
});

//======== Admin-panel actions ========

//add user
export const addNewUser = (data) => ({
  type: ADD_NEW_USER,
  payload: data,
});

//delete user
export const deleteUser = (data) => ({
  type: DELETE_USER,
  payload: data,
});

export const addCountToProductAlternate = (postObj) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(local.baseUrl + `api/v3/addCountToProductAlternate`, {
      method: "post",
      body: JSON.stringify(postObj),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    }).then((response) => {
      if (response.status === 200) {
      } else {
        logError(response);
        return;
      }
    });
  };
};

export const lockBugetAction = (lockBudget) => {
  var sessionData = getAccessToken();
  return fetch(
    local.baseUrl + `api/v3/group-user/lock-budget?lockBudget=${lockBudget}`,
    {
      method: "put",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + sessionData,
        ...getAdditionalHeaders(),
      }),
    }
  ).then((response) => {
    if (response.status === 200) {
    } else {
      logError(response);
      return;
    }
  });
};

export const excludeBudgetCategory = (categoryId) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(
      local.baseUrl +
      `api/v3/budget/exclude-category-budget?categoryId=${categoryId}`,
      {
        method: "get",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + sessionData,
          ...getAdditionalHeaders(),
        }),
      }
    ).then((response) => {
      if (response.status === 200) {
      } else {
        logError(response);
      }
      return response;
    });
  };
};

export const deleteExcludeBudgetCategory = (categoryId) => {
  var sessionData = getAccessToken();
  return (dispatch) => {
    return fetch(
      local.baseUrl +
      `api/v3/budget/delete-exclude-category-budget?categoryId=${categoryId}`,
      {
        method: "get",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + sessionData,
          ...getAdditionalHeaders(),
        }),
      }
    ).then((response) => {
      if (response.status === 200) {
      } else {
        logError(response);
      }
      return response;
    });
  };
};
