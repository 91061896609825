import { del, get, getNoAuth, post, postNoAuth, put } from './../request'
import * as ApiUrls from './apiUrls'

export const getProductDetailService = (productId) => {
    return get({
        url: ApiUrls.GET_PRODUCT_DETAILS(productId)
    })
}

export const getProductPriceService = (productId, defaultProductId, queryParams) => {
    return get({
        url: ApiUrls.GET_PRODUCT_PRICE(productId, defaultProductId),
        queryParams
    })
}

export const getAdminProductPriceService = (productId, companyId, defaultProductId, queryParams) => {
    return get({
        url: ApiUrls.GET_ADMIN_PRODUCT_PRICE(productId, companyId, defaultProductId),
        queryParams
    })
}

export const getWishListService = (queryParams) => {
    return get({
        url: ApiUrls.GET_WISH_LIST(),
        queryParams
    })
}

export const getProductManufacturerService = (manufacturerId, queryParams) => {
    return get({
        url: ApiUrls.GET_PRODUCT_MANUFACTURER(manufacturerId),
        queryParams
    })
}

export const addFavoriteItemService = (postObj) => {
    return post({
        url: ApiUrls.ADD_FAVORITE_ITEM(),
        data: JSON.stringify(postObj)
    })
}

export const deleteFavoriteItemService = (postObj) => {
    return del({
        url: ApiUrls.DELETE_FAVORITE_ITEM(),
        data: JSON.stringify(postObj)
    })
}

export const updateFavoriteItemService = (postObj) => {
    return put({
        url: ApiUrls.UPDATE_FAVORITE_ITEM(),
        data: JSON.stringify(postObj)
    })
}

export const unCheckFavoriteItemService = (postObj) => {
    return post({
        url: ApiUrls.UNCHECK_FAVORITE_ITEM(),
        data: JSON.stringify(postObj)
    })
}

export const getProductManufacturerMappingService = (queryParams) => {
    return get({
        url: ApiUrls.GET_MANUFACTURER_MAPPING(),
        queryParams
    })
}

export const getVendorListForMappingService = () => {
    return get({
        url: ApiUrls.GET_VENDOR_LIST_FOR_MAPPING(),
    })
}

export const searchMappedManufacturerService = (queryParams) => {
    return get({
        url: ApiUrls.SEARCH_MAPPED_MANUFACTURER(),
        queryParams
    })
}

export const searchManufacturerService = (queryParams) => {
    return get({
        url: ApiUrls.SEARCH_MANUFACTURER(),
        queryParams
    })
}

export const addMissedSavingNoteService = (queryParams) => {
    return put({
        url: ApiUrls.ADD_MISSED_SAVING_NOTE(),
        queryParams
    })
}

export const addExcludeProductService = (queryParams) => {
    return get({
        url: ApiUrls.ADD_EXCLUDE_PRODUCT(),
        queryParams
    })
}


export const deleteExcludeProductService = (queryParams) => {
    return get({
        url: ApiUrls.DELETE_EXCLUDE_PRODUCT(),
        queryParams
    })
}