import * as React from "react";

function SystemConfigurationSvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ marginRight: "10px" }}
    >
      <path
        d="M21.75 3.28125H2.25C1.83516 3.28125 1.5 3.61641 1.5 4.03125V15.6562C1.5 16.0711 1.83516 16.4062 2.25 16.4062H11.1562V19.0312H7.125C6.91875 19.0312 6.75 19.2 6.75 19.4062V20.5312C6.75 20.6344 6.83437 20.7188 6.9375 20.7188H17.0625C17.1656 20.7188 17.25 20.6344 17.25 20.5312V19.4062C17.25 19.2 17.0813 19.0312 16.875 19.0312H12.8438V16.4062H21.75C22.1648 16.4062 22.5 16.0711 22.5 15.6562V4.03125C22.5 3.61641 22.1648 3.28125 21.75 3.28125ZM20.8125 14.7188H3.1875V4.96875H20.8125V14.7188Z"
        fill={props.color}
        fill-opacity="0.7"
      />
    </svg>
  );
}

export default SystemConfigurationSvgComponent;
