/**
 * store
 */
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import { createLogger } from "redux-logger";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const loggerMiddleware = createLogger();

export function configureStore() {
	const store = createStore(
		reducers,
		composeEnhancer(applyMiddleware(thunk
			// , loggerMiddleware
			))
	)
	return store;
}

