const MAX_IMAGE_SIZE = 20; // In Mb
const MAX_EXCEL_SIZE = 50; // In Mb

export const checkImageSize = (file, showAlert) => {
    if (file) {
        if ((file.size / 1024 / 1024 > MAX_IMAGE_SIZE)) {
            showAlert("Image too large.", "error");
            return false;
        } else {
            return true;
        }
    } else {
        showAlert("Please select a file to upload.", "error");
    }
};


export const checkExcelSize = (file, showAlert) => {
    if (file) {
        if ((file.size / 1024 / 1024 > MAX_EXCEL_SIZE)) {
            showAlert("File too large", "error");
            return false;
        } else {
            return true;
        }
    } else {
        showAlert("Please select a file to upload.", "error");
    }
};

export const formattedPrice = (price) => {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(price);
}

