import * as React from "react";

function GroupsSvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ marginRight: "10px" }}
    >
      <path
        d="M21.375 19.2211V4.77889C22.0312 4.54686 22.5 3.92108 22.5 3.18748C22.5 2.25467 21.7453 1.49998 20.8125 1.49998C20.0789 1.49998 19.4531 1.96873 19.2211 2.62498H4.77891C4.54687 1.96873 3.92109 1.49998 3.1875 1.49998C2.25469 1.49998 1.5 2.25467 1.5 3.18748C1.5 3.92108 1.96875 4.54686 2.625 4.77889V19.2211C1.96875 19.4531 1.5 20.0789 1.5 20.8125C1.5 21.7453 2.25469 22.5 3.1875 22.5C3.92109 22.5 4.54687 22.0312 4.77891 21.375H19.2211C19.4531 22.0312 20.0789 22.5 20.8125 22.5C21.7453 22.5 22.5 21.7453 22.5 20.8125C22.5 20.0789 22.0312 19.4531 21.375 19.2211ZM20.8125 2.62498C21.1242 2.62498 21.375 2.87577 21.375 3.18748C21.375 3.4992 21.1242 3.74998 20.8125 3.74998C20.5008 3.74998 20.25 3.4992 20.25 3.18748C20.25 2.87577 20.5008 2.62498 20.8125 2.62498ZM3.1875 21.375C2.87578 21.375 2.625 21.1242 2.625 20.8125C2.625 20.5008 2.87578 20.25 3.1875 20.25C3.49922 20.25 3.75 20.5008 3.75 20.8125C3.75 21.1242 3.49922 21.375 3.1875 21.375ZM3.1875 3.74998C2.87578 3.74998 2.625 3.4992 2.625 3.18748C2.625 2.87577 2.87578 2.62498 3.1875 2.62498C3.49922 2.62498 3.75 2.87577 3.75 3.18748C3.75 3.4992 3.49922 3.74998 3.1875 3.74998ZM19.6875 19.6875H4.3125V4.31248H19.6875V19.6875ZM20.8125 21.375C20.5008 21.375 20.25 21.1242 20.25 20.8125C20.25 20.5008 20.5008 20.25 20.8125 20.25C21.1242 20.25 21.375 20.5008 21.375 20.8125C21.375 21.1242 21.1242 21.375 20.8125 21.375Z"
        fill={props.color}
        fill-opacity="0.7"
      />
      <path
        d="M6.75 11.1094H17.25C17.4563 11.1094 17.625 10.9406 17.625 10.7344V6.60936C17.625 6.40311 17.4563 6.23436 17.25 6.23436H6.75C6.54375 6.23436 6.375 6.40311 6.375 6.60936V10.7344C6.375 10.9406 6.54375 11.1094 6.75 11.1094ZM8.0625 7.92186H15.9375V9.42186H8.0625V7.92186ZM6.75 17.7656H17.25C17.4563 17.7656 17.625 17.5969 17.625 17.3906V13.2656C17.625 13.0594 17.4563 12.8906 17.25 12.8906H6.75C6.54375 12.8906 6.375 13.0594 6.375 13.2656V17.3906C6.375 17.5969 6.54375 17.7656 6.75 17.7656ZM8.0625 14.5781H15.9375V16.0781H8.0625V14.5781Z"
        fill={props.color}
        fill-opacity="0.7"
      />
    </svg>
  );
}

export default GroupsSvgComponent;
