import * as React from "react";

function ManufacturerMappingSvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ marginRight: "10px" }}
    >
      <path
        d="M21.2812 15H18.8438V11.4375C18.8438 11.3344 18.7594 11.25 18.6562 11.25H12.8438V9H15.375C15.5813 9 15.75 8.83125 15.75 8.625V1.875C15.75 1.66875 15.5813 1.5 15.375 1.5H8.625C8.41875 1.5 8.25 1.66875 8.25 1.875V8.625C8.25 8.83125 8.41875 9 8.625 9H11.1562V11.25H5.34375C5.24062 11.25 5.15625 11.3344 5.15625 11.4375V15H2.71875C2.5125 15 2.34375 15.1687 2.34375 15.375V22.125C2.34375 22.3312 2.5125 22.5 2.71875 22.5H9.46875C9.675 22.5 9.84375 22.3312 9.84375 22.125V15.375C9.84375 15.1687 9.675 15 9.46875 15H6.84375V12.9375H17.1562V15H14.5312C14.325 15 14.1562 15.1687 14.1562 15.375V22.125C14.1562 22.3312 14.325 22.5 14.5312 22.5H21.2812C21.4875 22.5 21.6562 22.3312 21.6562 22.125V15.375C21.6562 15.1687 21.4875 15 21.2812 15ZM8.0625 16.7812V20.7188H4.125V16.7812H8.0625ZM10.0312 7.21875V3.28125H13.9688V7.21875H10.0312ZM19.875 20.7188H15.9375V16.7812H19.875V20.7188Z"
        fill={props.color}
        fill-opacity="0.7"
      />
    </svg>
  );
}

export default ManufacturerMappingSvgComponent;
