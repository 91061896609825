import * as React from "react";

function ProgramsSvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ marginRight: "10px" }}
    >
      <path
        d="M20.625 2.62498H3.375C2.96016 2.62498 2.625 2.96014 2.625 3.37498V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V3.37498C21.375 2.96014 21.0398 2.62498 20.625 2.62498ZM19.6875 19.6875H4.3125V4.31248H19.6875V19.6875Z"
        fill="#F5F6FA"
        fill-opacity="0.7"
      />
      <path
        d="M8.69063 12.9937C7.41094 12.9937 6.375 14.0297 6.375 15.3094C6.375 16.589 7.41094 17.625 8.69063 17.625C9.97031 17.625 11.0063 16.589 11.0063 15.3094V14.3156H12.9914V15.3094C12.9914 16.589 14.0273 17.625 15.307 17.625C16.5867 17.625 17.6227 16.589 17.6227 15.3094C17.6227 14.0297 16.5867 12.9937 15.307 12.9937H14.3133V11.0086H15.307C16.5867 11.0086 17.6227 9.97264 17.6227 8.69295C17.6227 7.41327 16.5867 6.37733 15.307 6.37733C14.0273 6.37733 12.9914 7.41327 12.9914 8.69295V9.6867H11.0063V8.69295C11.0063 7.41327 9.97031 6.37733 8.69063 6.37733C7.41094 6.37733 6.375 7.41092 6.375 8.69061C6.375 9.9703 7.41094 11.0062 8.69063 11.0062H9.68438V12.9914H8.69063V12.9937ZM9.68438 15.3094C9.68438 15.8578 9.23906 16.3031 8.69063 16.3031C8.14219 16.3031 7.69688 15.8578 7.69688 15.3094C7.69688 14.7609 8.14219 14.3156 8.69063 14.3156H9.68438V15.3094ZM14.3156 8.69061C14.3156 8.14217 14.7609 7.69686 15.3094 7.69686C15.8578 7.69686 16.3031 8.14217 16.3031 8.69061C16.3031 9.23905 15.8578 9.68436 15.3094 9.68436H14.3156V8.69061ZM14.3156 14.3156H15.3094C15.8578 14.3156 16.3031 14.7609 16.3031 15.3094C16.3031 15.8578 15.8578 16.3031 15.3094 16.3031C14.7609 16.3031 14.3156 15.8578 14.3156 15.3094V14.3156ZM11.0063 11.0062H12.9914V12.9914H11.0063V11.0062ZM8.69063 9.68436C8.14219 9.68436 7.69688 9.23905 7.69688 8.69061C7.69688 8.14217 8.14219 7.69686 8.69063 7.69686C9.23906 7.69686 9.68438 8.14217 9.68438 8.69061V9.68436H8.69063Z"
        fill={props.color}
        fill-opacity="0.7"
      />
    </svg>
  );
}

export default ProgramsSvgComponent;
