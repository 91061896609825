/**
 * Main App
 */
import React, { Fragment } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { IntlProvider } from "react-intl";
import { Route, Switch } from "react-router-dom";
import { logout } from "./actions/action";
import AlertDialog from "./components/global/processing_dialogbox/dialogbox";
import VendorConnect from "./components/global/vendor-connect";
import Savings from "./components/global/savings";
import "./lib/embryoCss.js";
import { local } from "./constant";

import "./app.css";
import AppLocale from "./lang";
import HeaderOne from "./components/layouts/headers/HeaderOne";
import ProductReview from "./components/global/review-popup/ProductReview";
import Rollbar from "rollbar";
import LiveChat from "react-livechat";
import ReactHtmlParser from "react-html-parser";
import {
  AsyncBlogDetailComponent,
  AsyncFAQComponent,
  AsyncTermAndConditionComponent,
  AsyncSignInPageComponent,
  AsyncSignUpComponent,
  AsyncViewProductsComponent,
  AsyncForgetPasswordComponent,
  AsyncUserProfileComponent,
  AsyncThankYouComponent,
  AsyncPageNotFoundComponent,
  UserLogin,
  AsyncVendorsDetailsComponent,
  AsyncResetPasswordComponent,
  AsyncErrorPageComponent,
  AsyncPrivacyPolicyComponent,
  AsyncSessionGroupComponent,
  ThirdPartyLogin,
  AsyncManageOrder,
  AsyncViewReleaseNotesComponent,
} from "./util/AsyncRoutes";

import { customerRoutes, supportRoutes } from "./routes";
import {
  hideAlert,
  hideError,
  logErrorSuccess,
  showAddToCart,
  setAppLogo,
  showVendorConnectPopup,
  hideVendorConnectPopup,
  showSavingsPopup,
  hideSavingsPopup,
  showSamplesPopup,
  hideSamplesPopup,
  setRole,
} from "./actions/action";

import primaryTheme from "./themes/primaryTheme";
import ThemeOptions from "./components/ThemeOptions/ThemeOptions";
import AdminLayout from "./components/AdminLayout";
import "./css/style.css";
import RoutePrivate from "./private_route";
import IdleTimer from "react-idle-timer";
import { Dialog as PrimeDialog } from "primereact/dialog";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";

class App extends React.Component {
  /**
   * method for update window top when new page render
   */ constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.state = {
      params: [],
      themeDetails: {},
      loading: true,
      rollbar: new Rollbar(local.rollbarConfig),
      popUp: false,
      checkQuickCartSummaryVisible: !!JSON.parse(
        sessionStorage.getItem("checkQuickCartSummaryVisible"),
      ),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.activeCompany !== this.props.activeCompany ||
      nextProps.profile !== this.props.profile
    ) {
      let item = document.getElementById("chat-widget-container");
      if (item !== null) {
        item.style.display = "block";
      }
      let params = [
        {
          name: "Customer Name",
          value: `${
            nextProps.profile && nextProps.profile.firstName
              ? nextProps.profile.firstName
              : "--"
          } ${
            nextProps.profile && nextProps.profile.lastName
              ? nextProps.profile.lastName
              : "--"
          }`,
        },
        {
          name: "Email",
          value: `${
            nextProps.profile && nextProps.profile.userName
              ? nextProps.profile.userName
              : "--"
          }`,
        },
        {
          name: "Company Name",
          value: `${
            nextProps.activeCompany && nextProps.activeCompany.name
              ? nextProps.activeCompany.name
              : "--"
          }`,
        },
      ];
      this.setState({ params });
    }
  }

  _onActive(e) {
    // console.log('user is active', e)
  }

  _onIdle = (e) => {
    const isRememberMe = localStorage.getItem("isRememberMe");
    const expiryTime = localStorage.getItem("expiryTime");
    if (
      this.props.location.pathname === "/" ||
      this.props.location.pathname == "/admin"
    ) {
    } else {
      let shouldLogout = true;
      if (isRememberMe && expiryTime) {
        let expiryDateTime = new Date(expiryTime);
        let currentDateTime = new Date();
        if (expiryDateTime > currentDateTime) {
          shouldLogout = false;
        }
      }
      if (shouldLogout) {
        this.logoutFunction();
      }
    }
  };

  logoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    let item = document.getElementById("chat-widget-container");
    if (item !== null) {
      item.style.display = "none";
    }
    this.props.logout().then((res) => {
      window.location.replace(
        process?.env?.PUBLIC_URL + this.props.location.pathname,
      );
    });
  };

  openRedirectPopup = () => {
    this.setState({ popUp: true }, () => {
      setTimeout(() => window.open("https://shop.psivet.com", "_self"), 7000);
    });
  };

  componentDidMount() {
    this.state.rollbar.info(`Test call`);
    window.process = { ...window.process };
    let role = sessionStorage.getItem("role");
    this.props.setRole(role);
    if (role !== "CUSTOMER") {
      let redirectUrl = window.location.pathname;
      if (redirectUrl && redirectUrl != "/" && !redirectUrl.includes("admin")) {
        if (window.location.search) {
          redirectUrl += window.location.search;
        }
        sessionStorage.setItem("bookmarkUrl", redirectUrl);
      }
    }
    let url = "";
    if (
      window.location.pathname &&
      window.location.pathname.includes("admin")
    ) {
      url = "default";
    } else {
      url = window.location.host;
    }
    if (url === "psivet.vetscout.com") {
      this.openRedirectPopup();
    }
    // url = "psivet-dev.vetscout.com";
    fetch(`${local.baseUrl}api/v1/whitelabel?domainUrl=${url}`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          themeDetails: data.attributes,
          loading: false,
          enableChat: data.enableChat,
        });
        data.attributes["domainUrl"] = url;
        this.props.setAppLogo(data.attributes);
        let fevico = document.getElementById("fevicon");
        let title = document.getElementById("browser-title");
        if (
          data.attributes["BROWSER_TITLE_LABEL"] &&
          data.attributes["BROWSER_TITLE_LABEL"] != "" &&
          title
        ) {
          title.innerText = data.attributes["BROWSER_TITLE_LABEL"];
        }
        if (
          data.attributes["FEVICON_IMAGE_URL"] &&
          data.attributes["FEVICON_IMAGE_URL"] != "" &&
          fevico
        ) {
          fevico.href = data.attributes["FEVICON_IMAGE_URL"];
        }
        const script = document.createElement("script");
        if (data.attributes["GOOGLE_ANALYTICS_ID_LABEL"]) {
          script.async = true;
          script.src = `https://www.googletagmanager.com/gtag/js?id=${data.attributes["GOOGLE_ANALYTICS_ID_LABEL"]}`;

          const scriptConfig = document.createElement("script");
          scriptConfig.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config','${data.attributes["GOOGLE_ANALYTICS_ID_LABEL"]}');
          `;

          document.head.appendChild(script);
          document.head.appendChild(scriptConfig);
        } else {
          script.async = true;
          script.src = `https://www.googletagmanager.com/gtag/js?id=G-KDDNSPF689`;

          const scriptConfig = document.createElement("script");
          scriptConfig.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config','G-KDDNSPF689');
          `;

          document.head.appendChild(script);
          document.head.appendChild(scriptConfig);
        }
      })
      .catch(console.log);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.hideError();
      window.scrollTo(0, 0);
    }
    if (
      this.props.logError &&
      prevProps.logErrorType != this.props.logErrorType
    ) {
      let errorObj = {
        url: this.props.logErrorType.url,
        status: this.props.logErrorType.status,
      };
      if (
        process?.env?.REACT_APP_ENVIRONMENT == "staging" ||
        process?.env?.REACT_APP_ENVIRONMENT == "production"
      ) {
        this.state.rollbar.error(
          `Api Error->${errorObj.url} has status ${errorObj.status}`,
        );
      }
      this.props.logErrorSuccess();
    }
    // set checkQuickCartSummaryVisible session variable when active cart is closed.
    if (
      prevProps.showAddToCartPopup.flag &&
      !this.props.showAddToCartPopup.flag
    ) {
      sessionStorage.setItem(
        "checkQuickCartSummaryVisible",
        this.state.checkQuickCartSummaryVisible,
      );
    }
  }

  getUrl(pathname) {
    let pathArray = pathname.split("/");
    return `${pathArray[1]}` === "admin-panel" ? true : false;
  }
  hideConfirmCart(flag, value) {
    this.props.showAddToCart(flag, value);
  }
  redirectToCart = (flag, value) => {
    this.props.history.push("/cart");
    this.props.showAddToCart(flag, value);
  };
  showConfirmCartModal = () => {
    const { showAddToCartPopup } = this.props;
    const doNotShowStatus = JSON.parse(
      sessionStorage.getItem("checkQuickCartSummaryVisible"),
    );
    if (showAddToCartPopup.flag && !doNotShowStatus) {
      return true;
    }
    return false;
  };
  hideAddtoCart = () => {
    this.props.showAddToCart(
      false,
      this.props.showAddToCartPopup?.productsCopy,
    );
  };

  render() {
    const themeSecondaryColor = true;
    const root = document.documentElement;
    root.style.setProperty(
      "--active",
      themeSecondaryColor ? this.state.themeDetails?.ACTIVE_COLOR : "#FF5722",
    );
    root.style.setProperty(
      "--primary",
      themeSecondaryColor ? this.state.themeDetails?.PRIMARY_COLOR : "#283593",
    );
    root.style.setProperty(
      "--secondary",
      themeSecondaryColor
        ? this.state.themeDetails?.SECONDARY_COLOR
        : "rgba(0, 0, 0, 0.54)",
    );

    const { location } = this.props;
    const { popUp } = this.state;
    const {
      selectedLocale,
      alertType,
      showError,
      errorType,
      showAddToCartPopup,
      cartItemsCount,
      vendorConnectPopup,
      vendorConnectDetails,
      savingsPopup,
      savingsPopupDetails,
      samplesPopUp,
      samplePopUpDetails,
    } = this.props;
    const currentAppLocale = AppLocale[selectedLocale.locale];
    const role = sessionStorage.getItem("role");
    const support = JSON.parse(sessionStorage.getItem("support"));

    let themeDetailObj = {
      ...primaryTheme,

      palette: {
        ...primaryTheme.palette,
        action: {
          ...primaryTheme.palette.action,
          active: this.state.themeDetails?.SECONDARY_COLOR
            ? this.state.themeDetails?.SECONDARY_COLOR
            : "#26348F",
        },
        primary: {
          ...primaryTheme.palette.primary,
          main: this.state.themeDetails?.PRIMARY_COLOR
            ? this.state.themeDetails?.PRIMARY_COLOR
            : "#26348F",
        },
        secondary: {
          ...primaryTheme.palette.secondary,
          main: this.state.themeDetails?.SECONDARY_COLOR
            ? this.state.themeDetails?.SECONDARY_COLOR
            : "#FF5722",
        },
      },
    };

    return this.state.loading ? (
      <AlertDialog isOpen={true} />
    ) : (
      <MuiThemeProvider theme={themeDetailObj}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            timeout={1000 * 60 * 15}
          >
            <Fragment>
              {this.getUrl(location.pathname) ? (
                <Fragment>
                  <Route path="/admin-panel" component={AdminLayout} />
                </Fragment>
              ) : (
                <div className="app-container">
                  {location.pathname !== "/admin" ? (
                    <HeaderOne
                      history={this.props.history}
                      location={this.props.location}
                    />
                  ) : (
                    ""
                  )}

                  <ProductReview />

                  {showError ? (
                    <AsyncErrorPageComponent errorType={errorType} />
                  ) : (
                    <Switch>
                      {role !== "CUSTOMER" ? (
                        <Route exact path="/" component={UserLogin} />
                      ) : (
                        <Fragment>
                          {customerRoutes.map((route, i) => {
                            return (
                              <RoutePrivate
                                exact
                                path={route.path}
                                component={route.component}
                                key={i}
                              />
                            );
                          })}

                          {support
                            ? supportRoutes.map((route, i) => {
                                return (
                                  <RoutePrivate
                                    exact
                                    path={route.path}
                                    component={route.component}
                                    key={i}
                                  />
                                );
                              })
                            : ""}
                          <RoutePrivate
                            path="/userProfile"
                            component={AsyncUserProfileComponent}
                          />
                          <RoutePrivate
                            component={AsyncPageNotFoundComponent}
                          />
                          {this.state.enableChat &&
                          this.state.params &&
                          this.state.params.length > 0 ? (
                            <LiveChat
                              license={12486945}
                              params={this.state.params}
                            />
                          ) : (
                            ""
                          )}
                        </Fragment>
                      )}
                      <Route
                        path="/admin"
                        component={AsyncSignInPageComponent}
                      />

                      <RoutePrivate
                        path="/term-and-condition"
                        component={AsyncTermAndConditionComponent}
                      />

                      <RoutePrivate path="/faq" component={AsyncFAQComponent} />
                      <RoutePrivate
                        path="/orders/manage-order"
                        component={AsyncManageOrder}
                      />
                      <RoutePrivate
                        path="/viewProducts"
                        component={AsyncViewProductsComponent}
                      />
                      <RoutePrivate
                        path="/blogs/detail/:id"
                        component={AsyncBlogDetailComponent}
                      />
                      <Route
                        path="/thank-you"
                        component={AsyncThankYouComponent}
                      />
                      <Route path="/sign-up" component={AsyncSignUpComponent} />
                      <Route
                        path="/Integrations/CreateCallback"
                        exact={false}
                        component={ThirdPartyLogin}
                      />
                      <Route
                        path="/vendors"
                        component={AsyncVendorsDetailsComponent}
                      />
                      <Route
                        path="/session/group"
                        component={AsyncSessionGroupComponent}
                      />

                      <Route
                        path="/forget-password"
                        component={AsyncForgetPasswordComponent}
                      />
                      <Route
                        path="/privacy-policy"
                        component={AsyncPrivacyPolicyComponent}
                      />

                      <Route
                        path="/release-notes"
                        component={AsyncViewReleaseNotesComponent}
                      />

                      <Route
                        path="/reset-password"
                        component={AsyncResetPasswordComponent}
                      />

                      <RoutePrivate component={AsyncPageNotFoundComponent} />
                    </Switch>
                  )}
                  <div className="card">
                    <PrimeDialog
                      visible={this.showConfirmCartModal()}
                      header="Quick Cart Summary"
                      onHide={() => {
                        this.props.showAddToCart(
                          false,
                          showAddToCartPopup?.productsCopy,
                        );
                      }}
                      className="confirm_cart_modal main-dialogue"
                      aria-labelledby="responsive-dialog-title"
                    >
                      <Grid className="main-parent">
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className="confirm_box">
                            <div className="cart_title">
                              <i className="material-icons icon_check">
                                check_icon
                              </i>{" "}
                              You've added to your cart{" "}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className="confirm_cart_item">
                            {" "}
                            {cartItemsCount
                              ? `Cart contains:  ${cartItemsCount} ${
                                  cartItemsCount > 1 ? `Items` : `Item`
                                }`
                              : ""}
                          </div>
                        </Grid>
                      </Grid>

                      <Grid className="body-parent">
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div className="product_image_div">
                            <img
                              src={
                                showAddToCartPopup?.productsCopy?.images[0]
                                  ?.externalUrl
                                  ? local.imageUrl +
                                    showAddToCartPopup?.productsCopy?.images[0]
                                      ?.externalUrl
                                  : ""
                              }
                              alt="product-image"
                              width="100"
                              className="product_image_modal"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                          <FormControl>
                            <div className="prod_desc">
                              {" "}
                              {showAddToCartPopup?.productsCopy?.description
                                ?.name
                                ? showAddToCartPopup?.productsCopy?.description
                                    ?.name
                                : ""}
                            </div>
                            <div className="prod_desc prod_qty">
                              {" "}
                              {showAddToCartPopup?.productsCopy?.productQuantity
                                ? `Qty :  ${showAddToCartPopup?.productsCopy?.productQuantity}`
                                : ""}
                            </div>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <div style={{ height: "100%" }}>
                            <div className="shopping_button">
                              <Button
                                className="view_cart_btn"
                                onClick={() => {
                                  this.redirectToCart(
                                    false,
                                    showAddToCartPopup?.productsCopy,
                                  );
                                }}
                              >
                                View cart
                              </Button>
                              <Button
                                id="yes"
                                style={{ marginLeft: "15px" }}
                                onClick={() => {
                                  this.props.showAddToCart(
                                    false,
                                    showAddToCartPopup?.productsCopy,
                                  );
                                }}
                                className="button btn-active cntinue-shp"
                              >
                                Continue Shopping
                              </Button>
                            </div>
                            <FormControlLabel
                              style={{ float: "right", margin: 0 }}
                              control={
                                <Checkbox
                                  style={{ padding: 0 }}
                                  color="primary"
                                  checked={
                                    this.state.checkQuickCartSummaryVisible
                                  }
                                  onChange={(evt) =>
                                    this.setState({
                                      checkQuickCartSummaryVisible:
                                        evt.target.checked,
                                    })
                                  }
                                />
                              }
                              label="Do not show in the future"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </PrimeDialog>
                  </div>
                  {vendorConnectPopup ? (
                    <VendorConnect
                      vendor={vendorConnectDetails}
                      show={vendorConnectPopup}
                      hidePopup={this.props.hideVendorConnectPopup}
                    />
                  ) : (
                    ""
                  )}
                  {savingsPopup ? (
                    <Savings
                      data={savingsPopupDetails}
                      show={savingsPopup}
                      hidePopup={this.props.hideSavingsPopup}
                    />
                  ) : (
                    ""
                  )}
                  {samplesPopUp ? (
                    <Savings
                      data={samplePopUpDetails}
                      show={samplesPopUp}
                      hidePopup={this.props.hideSamplesPopup}
                    />
                  ) : (
                    ""
                  )}
                  <Dialog
                    fullScreen
                    open={popUp}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogContent className="p-20 text-center">
                      <h4
                        style={{
                          textTransform: "none",
                          fontSize: "2.5rem",
                          lineHeight: "4rem",
                        }}
                        className="pt-20"
                      >
                        We've moved!
                        <br />
                        You will be automatically redirected to our new website
                        at{" "}
                        <a
                          style={{
                            textDecoration: "underline",
                            fontSize: "2.5rem",
                            color: "var(--active)",
                          }}
                          onClick={() => {
                            window.open("https://shop.psivet.com", "_self");
                          }}
                        >
                          shop.psivet.com
                        </a>
                        <br />
                        Please update your bookmark.
                      </h4>
                      <img
                        src={
                          "https://vet-price-check.s3.us-west-2.amazonaws.com/practice/psivet-original.png"
                        }
                        alt={"psivet"}
                      />
                    </DialogContent>
                  </Dialog>
                  <SweetAlert
                    success={alertType === "success"}
                    error={alertType === "error"}
                    title=""
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="warning"
                    className="iron-alert-box"
                    show={this.props.showAlert}
                    onConfirm={this.props.hideAlert}
                    onCancel={this.props.hideAlert}
                    closeOnClickOutside
                  >
                    {ReactHtmlParser(this.props.alertMessage)}
                  </SweetAlert>
                </div>
              )}
              <ThemeOptions url={this.getUrl(location.pathname)} />
            </Fragment>
          </IdleTimer>
        </IntlProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ appSettings, ecommerce }) => {
  const {
    showAlert,
    alertMessage,
    hasCheckbox,
    selectedLocale,
    alertType,
    showError,
    errorType,
    logError,
    logErrorType,
  } = appSettings;
  const {
    profile,
    activeCompany,
    showAddToCartPopup,
    cartItemsCount,
    vendorConnectPopup,
    vendorConnectDetails,
    savingsPopup,
    savingsPopupDetails,
    samplesPopUp,
    samplePopUpDetails,
  } = ecommerce;
  return {
    showAlert,
    alertMessage,
    hasCheckbox,
    selectedLocale,
    alertType,
    showError,
    errorType,
    profile,
    activeCompany,
    logError,
    logErrorType,
    showAddToCartPopup,
    cartItemsCount,
    vendorConnectPopup,
    vendorConnectDetails,
    savingsPopup,
    savingsPopupDetails,
    samplesPopUp,
    samplePopUpDetails,
  };
};

export default connect(mapStateToProps, {
  hideAlert,
  hideError,
  logErrorSuccess,
  showAddToCart,
  logout,
  setAppLogo,
  showVendorConnectPopup,
  hideVendorConnectPopup,
  showSavingsPopup,
  hideSavingsPopup,
  showSamplesPopup,
  hideSamplesPopup,
  setRole,
})(App);
