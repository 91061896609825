export const GET_PRODUCT_DETAILS = (productId) => {
    return `api/v3/products/${productId}`;
}

export const GET_ADMIN_PRODUCT_PRICE = (productId, companyId, defaultProductId) => {
    return `api/v1/products/price/${productId}/${companyId}/${defaultProductId}`;
}

export const GET_PRODUCT_PRICE = (productId, defaultProductId) => {
    return `api/v4/products/price/${productId}/${defaultProductId}`;
}

export const GET_WISH_LIST = () => {
    return `api/v4/wishlistName`;
}

export const ADD_FAVORITE_ITEM = () => {
    return `api/v4/wishListItem`;
}

export const DELETE_FAVORITE_ITEM = () => {
    return `api/v4/deleteWishlistName`;
}

export const UPDATE_FAVORITE_ITEM = () => {
    return `api/v4/updateWishlistName`;
}

export const UNCHECK_FAVORITE_ITEM = () => {
    return `api/v4/removeWishListItem`;
}

export const GET_PRODUCT_MANUFACTURER = (manufacturerId) => {
    return `api/v1/products/manufacturer/${manufacturerId}`
}

export const GET_MANUFACTURER_MAPPING = () => {
    return `api/v1/manufacturerMapping/child`
}

export const GET_VENDOR_LIST_FOR_MAPPING = () => {
    return `api/v1/manufacturerMapping/merchantList`
}

export const SEARCH_MAPPED_MANUFACTURER = () => {
    return `api/v1/manufacturerMapping/suggestionList`
}

export const SEARCH_MANUFACTURER = () => {
    return `api/v1/manufacturerMapping/list`
}

export const ADD_MISSED_SAVING_NOTE = () => {
    return `api/v3/order/missed-savings/note`
}

export const ADD_EXCLUDE_PRODUCT = () => {
    return `api/v3/budget/exclude-budget`
}


export const DELETE_EXCLUDE_PRODUCT = () => {
    return `api/v3/budget/delete-exclude-budget`
}