/**
 * AdminInfo header widget
 */
/* eslint-disable */
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import userImage from "../../../assets/avatars/user-4.jpg";

class AdminInfo extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    anchorEl: null,
    user: {
      url: userImage,
      alt: "user",
    },
    menus: [
      // {
      //    id: 1,
      //    title: 'Profile',
      //    icon: 'account_circle',
      //    path: '/admin-panel/admin/account/profile'
      // },
      // {
      //    id: 2,
      //    title: 'Account Settings',
      //    icon: 'settings',
      //    path: '/admin-panel/admin/account/settings'
      // },
      {
        id: 4,
        title: "Logout",
        icon: "power_settings_new",
        path: "/admin",
      },
    ],
  };

  //define function for open dropdown
  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  //define function for close dropdown
  handleClose = (e) => {
    if (e.target.innerHTML === "Logout") {
      sessionStorage.clear();
      this.props.logout().then((res) => {
        window.location.replace(process.env.PUBLIC_URL + "admin");
      });
    }
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const user = this.state.user;
    return (
      <div
        className="iron-logout-wrap"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Avatar
          aria-owns={open ? "menu-appbar" : null}
          aria-haspopup="true"
          className="icon-btn"
          alt={user.alt}
          src={
            "https://vet-price-check.s3.us-west-2.amazonaws.com/vetpricescout/profile_pic/noimage.jpg"
          }
        ></Avatar>
        <span className="admin-title">Admin</span>
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: "10px", cursor: "pointer" }}
          onClick={this.handleMenu}
        >
          <path
            d="M8.63281 2.5H7.90039C7.85059 2.5 7.80371 2.52441 7.77442 2.56445L5 6.38867L2.22559 2.56445C2.19629 2.52441 2.14942 2.5 2.09961 2.5H1.36719C1.30371 2.5 1.2666 2.57227 1.30371 2.62402L4.74707 7.37109C4.87207 7.54297 5.12793 7.54297 5.25195 7.37109L8.69531 2.62402C8.7334 2.57227 8.69629 2.5 8.63281 2.5Z"
            fill="#011154"
          />
        </svg>

        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={this.handleClose}
          className="iron-dropdown"
        >
          {this.state.menus.map((menu, index) => (
            <MenuItem
              key={index}
              onClick={this.handleClose}
              to={menu.path}
              component={Link}
              name={menu.title}
            >
              <i className="material-icons">{menu.icon}</i>
              <span style={{ width: "100%" }} className="mb-0">{menu.title}</span>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default AdminInfo;
