/**
 * action types
 */

export const SET_ROLE = 'SET_ROLE';
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';
export const MOVE_WISHLISTITEM_TO_CART = 'MOVE_WISHLISTITEM_TO_CART';
export const FINAL_PAYMENT = 'FINAL_PAYMENT';
export const GET_MYCOVETRUS_DATA = 'GET_MYCOVETRUS_DATA';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ERROR = 'HIDE_ERROR';
export const SHOW_ERROR = 'SHOW_ERROR';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_PRODUCT_QUANTITY = 'UPDATE_PRODUCT_QUANTITY';
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const RTL_LAYOUT = "RTL_LAYOUT";
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR';
export const DELETE_USER = 'DELETE_USER';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const DISPLAY_CART_ITEMS = "DISPLAY_CART_ITEMS";
export const DISPLAY_MERCHANT_CART_ITEMS = "DISPLAY_MERCHANT_CART_ITEMS";
export const ORDER_HISTORY = "ORDER_HISTORY";
export const DISPLAY_WISHLIST_ITEMS = "DISPLAY_WISHLIST_ITEMS";
export const SHOW_VENDOR_LIST = "SHOW_VENDOR_LIST";
export const DEFAULT_MERCHANT_DETAILS = "DEFAULT_MERCHANT_DETAILS";
export const SAVE_MERCHANT_DETAILS = "SAVE_MERCHANT_DETAILS";
export const NEW_USER_SIGNUP = "NEW_USER_SIGNUP";
export const UPDATE_MERCHANT_DETAILS = "UPDATE_MERCHANT_DETAILS";
export const UPDATE_CART = "UPDATE_CART";
export const PRODUCT_ORDER_HISTORY = "PRODUCT_ORDER_HISTORY";
export const MANUFACURE_ORDER_HISTORY = "MANUFACURE_ORDER_HISTORY";
export const PROFILE = "PROFILE";
export const LOGOUT = "LOGOUT";
export const ACTIVE_COMPANY = "ACTIVE_COMPANY";
export const COMPANIES = "COMPANIES";
export const COMPANY_WISH_LIST = "COMPANY_WISH_LIST";
export const ADD_FAVORITE_ITEM = "ADD_FAVORITE_ITEM";
export const DELETE_FAVORITE_ITEM = "DELETE_FAVORITE_ITEM";
export const UPDATE_FAVORITE_ITEM = "UPDATE_FAVORITE_ITEM";
export const COMPANY_UPDATE_FLAG = "COMPANY_UPDATE_FLAG";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT";
export const POPUP_NOTIFICATION = "POPUP_NOTIFICATION";
export const DISPLAY_MY_PRODUCTS_ITEMS = "DISPLAY_MY_PRODUCTS_ITEMS";
export const MANUFACTURER_PURCHASE_SUMMARY_REPORT = "MANUFACTURER_PURCHASE_SUMMARY_REPORT";
export const MERCHANT_PURCHASE_SUMMARY_REPORT = "MERCHANT_PURCHASE_SUMMARY_REPORT";
export const PRODUCT_PURCHASE_SUMMARY_REPORT = "PRODUCT_PURCHASE_SUMMARY_REPORT";
export const CATEGORY_PURCHASE_SUMMARY_REPORT = "CATEGORY_PURCHASE_SUMMARY_REPORT";
export const MANUFACTURER_FROM_REBATE = "MANUFACTURER_FROM_REBATE";
export const SHOW_GROUP_LIST = "SHOW_GROUP_LIST";
export const GROUP_AREA_MANAGERS = "GROUP_AREA_MANAGERS";
export const CARTITEMS = "CARTITEMS";
export const LOG_ERROR = "LOG_ERROR";
export const LOG_ERROR_SUCCESS = "LOG_ERROR_SUCCESS";
export const SHOW_ADD_TO_CART_POPUP = "SHOW_ADD_TO_CART_POPUP";
export const SET_APP_LOGO = "SET_APP_LOGO";
export const BUCKS = "BUCKS";
export const SHOW_VENDOR_CONNECT_POPUP = "SHOW_VENDOR_CONNECT_POPUP";
export const HIDE_VENDOR_CONNECT_POPUP = "HIDE_VENDOR_CONNECT_POPUP";
export const SET_INVALID_CREDENTIALS = "SET_INVALID_CREDENTIALS";
export const SET_EXPIRED_TOKEN = "SET_EXPIRED_TOKEN";
export const BUDGETS = "BUDGETS";
export const SHOW_SAVINGS_POPUP = "SHOW_SAVINGS_POPUP";
export const HIDE_SAVINGS_POPUP = "HIDE_SAVINGS_POPUP";
export const SHOW_SAMPLES_POPUP = "SHOW_SAMPLES_POPUP";
export const HIDE_SAMPLES_POPUP = "HIDE_SAMPLES_POPUP";
